import { ENUM_POPUP_PAYMENT_TYPE } from "components-v2/mocules/PopupVoucherPayment/interface";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface Actions {
  checkoutUpdatePromosRemoved: (payload: string) => void;
  resetPromosRemoved: () => void;
  togglePopup: (payload: boolean) => void;
  updateInvalidPromos: (payload: string[]) => void;
  updatePopupPaymentType: (payload: ENUM_POPUP_PAYMENT_TYPE) => void;
  updateInfoNextPaymentMethod: (payload?: IInfoNextPaymentMethod) => void;
  syncPaymentMethodConfigs: (payload: any) => void;
}

interface IInfoNextPaymentMethod {
  value: {
    customerName: string
    customerPhone: string
    customerEmail: string
    customerShippingAddress: string
    customerDistrictCode: string
    customerProvinceCode: string
    customerWardCode: string
    customerAddressCode: string
    customerRegionCode: string
    customerWardName: string
    customerDistrictName: string
    customerProvinceName: string
  },
  nextPaymentMethod: string,
  inValidPromos: string[]
  info: {
    cardList: string,
    code: string,
    description: string,
    name: string,
    partnerPaymentServiceLocationFees:
      {
          dynamicFeeConfig: {
              buymedFeeValue: number,
              customerFeeValue: number,
              isActive: boolean
          },
          fixedFeeConfig: {
              buymedFeeValue: number,
              customerFeeValue: number,
              isActive: boolean
          },
          locationCodes: string[]
      }[],
    paymentLocations: [
      {
          feeDiscountPercentage: number
          locationCodes: string[]
      },
      {
          feeDiscountPercentage: string
          locationCodes: string[]
      },
      {
          feeDiscountPercentage: number,
          locationCodes: string[]
      }
    ],
    priority: number,
    status: string,
    subLabel: string,
    subTitle: string,
    svgIcons: string[],
    url: string,
    mapLocationFee: {
      [x: string]: string
    },
    isDisable: boolean,
    defaultValue: any | null,
    errorMessage: boolean
  }[]
}

interface InitialCheckoutState {
  promosRemoved: string
  openPopup: boolean
  invalidPromos: string[]
  popupPaymentType?: ENUM_POPUP_PAYMENT_TYPE
  infoNextPaymentMethod?: IInfoNextPaymentMethod
  paymentMethodConfigs: {
    [x: string]: string
  }
}

type CheckoutState = InitialCheckoutState & { actions: Actions };

const INITIAL_STATE: InitialCheckoutState = {
  promosRemoved: '',
  openPopup: false,
  invalidPromos: [],
  popupPaymentType: undefined,
  paymentMethodConfigs: {}
}

const useCheckoutStates = create<CheckoutState>()(
  devtools((set) => ({
    ...INITIAL_STATE,
    actions: {
      checkoutUpdatePromosRemoved: (promosRemoved: string) => {
        set((state) => ({
          ...state,
          promosRemoved
        }));
      },
      resetPromosRemoved: () => {
        set((state) => ({
          ...state,
          promosRemoved: ''
        }));
      },
      togglePopup: (payload) => {
        set((state) => ({
          ...state,
          openPopup: payload
        }));
      },
      updateInvalidPromos: (invalidPromos) => {
        set((state) => ({
          ...state,
          invalidPromos
        }));
      },
      updatePopupPaymentType: (popupPaymentType) => {
        set((state) => ({
          ...state,
          popupPaymentType
        }));
      },
      updateInfoNextPaymentMethod: (infoNextPaymentMethod) => {
        set((state) => ({
          ...state,
          infoNextPaymentMethod
        }));
      },
      syncPaymentMethodConfigs: (paymentMethodConfigs) => {
        set((state) => ({
          ...state,
          paymentMethodConfigs
        }));
      }
    }
  }))
)

export const useCheckoutActions = () => useCheckoutStates((state) => state.actions);

export default useCheckoutStates;