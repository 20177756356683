import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getData, isValid } from 'clients/Clients';
import PromoClient from 'clients/PromoClient';
import clsx from 'clsx';
import InputSearchVoucher from 'components-v2/atoms/InputSearchVoucher';
import LoadingBM from 'components-v2/atoms/LoadingBM';
import NewCartCouponCardV2 from 'components-v2/mocules/NewCartCouponCartV2';
import { useCart } from 'context/Cart/CartContext';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { debounceFunc500 } from 'utils/debounce';
import useGuideCart from 'zustand-lib/useGuideCart';
import styles from './styles.module.css';

const TEXT_DEFAULT = '';
const modalVoucherTitle = 'Danh sách mã giảm giá';

const ENUM_SECTION_PROMO = {
  MARKETPLACE: 'MARKETPLACE',
  SELLER: 'SELLER',
};

function PromoListModalV2({
  onClose,
  visible,
  redeemCode,
  handleChangePromo,
  subPrice,
  accountId = 0,
  customerID,
  currentPage,
  isMobile,
  redeemApplyResult,
  paymentMethod,
  price,
  totalItem,
  cartItems,
  priceIncludePromo, // price with promo
  ...restProps
}) {
  const { availablePromoSectionRef, toggleOnCheckingPromo } = useCart();
  const { isOnGuide, toggleDoneFetchPromo } = useGuideCart();
  const mountRef = useRef(false);
  // 2 section promoList
  const [marketplacePromoList, setMarketPlacePromoList] = useState([]);
  const [totalMarketplacePromo, setTotalMarketplacePromo] = useState(0);

  const [sellerPromoList, setSellerPromoList] = useState([]);
  const [sellerPromoShowMore, setSellerPromoShowMore] = useState([]);
  const [limitSellerPromo, setLimitSellerPromo] = useState(2);
  const [totalSellerPromo, setTotalSellerPromo] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const isInitialMount = useRef(true);
  const [triggerReFetchPromo, setTriggerRefetchPromo] = useState(0);
  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const [isSellerFetching, setSellerFetching] = useState(false); // state for expand/collapse
  const [isOnChecking, setOnChecking] = useState(false); // state waiting after check/uncheck
  const [sectionPromoRefetch, setSectionPromoRefetch] = useState(''); // Marketpalce || Seller for fetching after check/uncheck
  const [initialPriceIncludePromo, setInitialPriceIncludePromo] = useState(priceIncludePromo); // use to calc local price with promo

  // TODO: on searching, remove expand/collapse, show all data
  // call on fill text searching
  const handleUpdateText = useCallback(
    async (value) => {
      const [respMarketplacePromos, respSellerPromos] = await Promise.all([
        PromoClient.postVoucherList({
          accountID: accountId,
          cart: {
            paymentMethod,
            price,
            totalItem,
            redeemCode,
            cartItems: cartItems
              ?.filter((item) => item.isSelected)
              ?.map((item) => ({
                quantity: item.quantity,
                price: item.price,
                total: item.total,
                sku: item.sku,
                sellerCode: item.sellerCode,
                productTags: item.productTags,
                productCode: item.productCode,
                isSelected: item.isSelected,
                storeCode: item.storeCode,
              })),
            redeemApplyResult: redeemApplyResult || [],
          },
          scope: 'marketplace',
          search: value,
          getVoucherAuto: true,
        }),
        PromoClient.postVoucherList({
          accountID: accountId,
          cart: {
            paymentMethod,
            price,
            totalItem,
            redeemCode,
            cartItems: cartItems
              ?.filter((item) => item.isSelected)
              ?.map((item) => ({
                quantity: item.quantity,
                price: item.price,
                total: item.total,
                sku: item.sku,
                sellerCode: item.sellerCode,
                productTags: item.productTags,
                productCode: item.productCode,
                isSelected: item.isSelected,
                storeCode: item.storeCode,
              })),
            redeemApplyResult: redeemApplyResult || [],
          },
          scope: 'seller',
          search: value,
          getBySellerInCart: true,
          getVoucherAuto: true,
        }),
      ]);

      if (isValid(respMarketplacePromos)) {
        setMarketPlacePromoList(getData(respMarketplacePromos));
        setTotalMarketplacePromo(respMarketplacePromos?.total || 0);
      } else {
        setMarketPlacePromoList([]);
        setTotalMarketplacePromo(0);
      }

      if (isValid(respSellerPromos)) {
        setSellerPromoList(getData(respSellerPromos));
        setSellerPromoShowMore(getData(respSellerPromos));
        setTotalSellerPromo(respSellerPromos?.total || 0);
        setLimitSellerPromo(respSellerPromos?.total || 0);
      } else {
        setSellerPromoList([]);
        setSellerPromoShowMore([]);
        setTotalSellerPromo(0);
        setLimitSellerPromo(0);
      }
      setLoadingSearch(false);
      // if (text === '' || promoList.length >= 20) {
      //   setIsHasMore(true);
      // }
    },
    // TODO: on edit cart after checkout, need add these to dep
    [redeemApplyResult, paymentMethod, price, totalItem, redeemCode, cartItems],
  );

  const handleChangeText = (e) => {
    setLoadingSearch(true);
    const valueText = e.target.value;
    // // mặc định api trả ra data length = 20 nếu nhỏ hơn thì ko load more
    // if (promoList?.length < 20) {
    //   setIsHasMore(false);
    // }
    setText(valueText);
    debounceFunc500(() => {
      handleUpdateText(valueText);
    });
  };

  const handleRemoveText = () => {
    setText(TEXT_DEFAULT);
    setLoadingSearch(true);
    setTriggerRefetchPromo((prev) => prev + 1);
  };

  // TODO: on clear text, base on user'current limit and total to display
  // on clear text or when triggerRefetch Promo after check/uncheck
  useEffect(() => {
    async function getConfigVoucherList() {
      if (!mountRef.current) {
        mountRef.current = true;
      }

      if (!sectionPromoRefetch) {
        const [respMarketplacePromos, respSellerPromos] = await Promise.all([
          PromoClient.postVoucherList({
            accountID: accountId,
            cart: {
              paymentMethod,
              price,
              totalItem,
              redeemCode,
              cartItems: cartItems
                ?.filter((item) => item.isSelected)
                ?.map((item) => ({
                  quantity: item.quantity,
                  price: item.price,
                  total: item.total,
                  sku: item.sku,
                  sellerCode: item.sellerCode,
                  productTags: item.productTags,
                  productCode: item.productCode,
                  isSelected: item.isSelected,
                  storeCode: item.storeCode,
                })),
              // redeemApplyResult,
              redeemApplyResult: redeemApplyResult || [],
            },
            scope: 'marketplace',
            search: text,
            getVoucherAuto: true,
          }),
          PromoClient.postVoucherList({
            accountID: accountId,
            cart: {
              paymentMethod,
              price,
              totalItem,
              redeemCode,
              cartItems: cartItems
                ?.filter((item) => item.isSelected)
                ?.map((item) => ({
                  quantity: item.quantity,
                  price: item.price,
                  total: item.total,
                  sku: item.sku,
                  sellerCode: item.sellerCode,
                  productTags: item.productTags,
                  productCode: item.productCode,
                  isSelected: item.isSelected,
                  storeCode: item.storeCode,
                })),
              // redeemApplyResult,
              redeemApplyResult: redeemApplyResult || [],
            },
            scope: 'seller',
            search: text,
            getBySellerInCart: true,
            getVoucherAuto: true,
          }),
        ]);
        if (isValid(respMarketplacePromos)) {
          setMarketPlacePromoList(getData(respMarketplacePromos));
          setTotalMarketplacePromo(respMarketplacePromos?.total || 0);
        }

        if (isValid(respSellerPromos)) {
          setSellerPromoList(getData(respSellerPromos));
          setSellerPromoShowMore(
            getData(respSellerPromos)
              ?.map((item) => item)
              ?.splice(0, 2),
          );
          setLimitSellerPromo(2);
          setTotalSellerPromo(respSellerPromos?.total || 0);
        }
      } else if (sectionPromoRefetch === ENUM_SECTION_PROMO.SELLER) {
        // TODO: render after check/unCheck with section is SELLER
        const respSellerPromos = await PromoClient.postVoucherList({
          accountID: accountId,
          cart: {
            paymentMethod,
            price,
            totalItem,
            redeemCode,
            cartItems: cartItems
              ?.filter((child) => child.isSelected)
              ?.map((item) => ({
                quantity: item.quantity,
                price: item.price,
                total: item.total,
                sku: item.sku,
                sellerCode: item.sellerCode,
                productTags: item.productTags,
                productCode: item.productCode,
                isSelected: item.isSelected,
                storeCode: item.storeCode,
              })),
            // redeemApplyResult,
            redeemApplyResult: redeemApplyResult || [],
          },
          scope: 'seller',
          getBySellerInCart: true,
          search: text,
          getVoucherAuto: true,
        });

        if (isValid(respSellerPromos)) {
          setSellerPromoList(getData(respSellerPromos));

          if (text || (limitSellerPromo > 0 && totalMarketplacePromo > 0)) {
            // TODO: Check after check/unchked, search then check/uncheck
            setSellerPromoShowMore(
              getData(respSellerPromos)
                ?.map((item) => item)
                ?.splice(0, limitSellerPromo),
            );
          }
          setTotalSellerPromo(respSellerPromos?.total || 0);

          // scroll into top section after check/uncheck
          const sectionEle = document?.getElementById('sellerSectionPromoList');
          if (sectionEle) {
            sectionEle.scrollIntoView(true);
          }
        }
      } else {
        // TODO: render after check/unCheck with section is MARKETPLACE
        const respMarketplacePromos = await PromoClient.postVoucherList({
          accountID: accountId,
          cart: {
            paymentMethod,
            price,
            totalItem,
            redeemCode,
            cartItems: cartItems
              ?.filter((child) => child.isSelected)
              ?.map((item) => ({
                quantity: item.quantity,
                price: item.price,
                total: item.total,
                sku: item.sku,
                sellerCode: item.sellerCode,
                productTags: item.productTags,
                productCode: item.productCode,
                isSelected: item.isSelected,
                storeCode: item.storeCode,
              })),
            // redeemApplyResult,
            redeemApplyResult: redeemApplyResult || [],
          },
          scope: 'marketplace',
          search: text,
          getVoucherAuto: true,
        });

        if (isValid(respMarketplacePromos)) {
          setMarketPlacePromoList(getData(respMarketplacePromos));
          setTotalMarketplacePromo(respMarketplacePromos?.total || 0);

          // scroll into top section after check/uncheck
          const sectionEle = document?.getElementById('marketSectionPromoList');
          if (sectionEle) {
            sectionEle.scrollIntoView(true);
          }
        }
      }

      setLoadingSearch(false);
      // on check/uncheck promo will re-fetch promoList, loading will from on check/uncheck and one after promo list have been fetched do
      setOnChecking(false);
      toggleOnCheckingPromo(false);
    }

    if (!isInitialMount.current) {
      getConfigVoucherList();
    } else {
      isInitialMount.current = false;
    }

    return () => setSectionPromoRefetch('');
  }, [triggerReFetchPromo]);

  // TODO: on open promoList, always display 1.5/each section
  // with new API. On open promoList, on click Button search
  // use isButtonSearch flag to catch is user click search
  // if have searching text, show all
  const searchPromo = useCallback(
    async (search, isButtonSearch = false) => {
      if (isSending) return;
      setIsSending(true);
      setLoading(true);
      toggleDoneFetchPromo({ isDoneFetchPromo: false });

      const bodyReqVoucherList = {
        paymentMethod,
        price,
        totalItem,
        redeemCode,
        cartItems: cartItems
          ?.filter((item) => item.isSelected)
          ?.map((item) => ({
            quantity: item.quantity,
            price: item.price,
            total: item.total,
            sku: item.sku,
            sellerCode: item.sellerCode,
            productTags: item.productTags,
            productCode: item.productCode,
            isSelected: item.isSelected,
            storeCode: item.storeCode,
          })),
        // redeemApplyResult,
        redeemApplyResult: redeemApplyResult || [],
      };

      const [searchMarketplaceRes, searchSellerRes] = await Promise.all([
        PromoClient.postVoucherList({
          accountID: accountId,
          cart: bodyReqVoucherList,
          scope: 'marketplace',
          search,
          getVoucherAuto: true,
        }),
        PromoClient.postVoucherList({
          accountID: accountId,
          cart: bodyReqVoucherList,
          scope: 'seller',
          search,
          getBySellerInCart: true,
          getVoucherAuto: true,
        }),
      ]);

      // check valid market promo
      if (isValid(searchMarketplaceRes)) {
        setMarketPlacePromoList(getData(searchMarketplaceRes));
        setTotalMarketplacePromo(searchMarketplaceRes?.total || 0);
      } else {
        setMarketPlacePromoList([]);
        setTotalMarketplacePromo(0);
      }

      // check valid seller promo
      if (isValid(searchSellerRes)) {
        setSellerPromoList(getData(searchSellerRes));
        if (isButtonSearch) {
          setLimitSellerPromo(searchSellerRes?.total || 0);
          setSellerPromoShowMore(
            getData(searchSellerRes)
              ?.map((item) => item)
              ?.splice(0, searchSellerRes?.total || 0),
          );
        } else {
          setLimitSellerPromo(search ? searchSellerRes?.total : 2);
          setSellerPromoShowMore(
            getData(searchSellerRes)
              ?.map((item) => item)
              ?.splice(0, search ? searchSellerRes?.total : 2),
          );
        }

        setTotalSellerPromo(searchSellerRes?.total || 0);
      } else {
        setSellerPromoList([]);
        setSellerPromoShowMore([]);
        setTotalSellerPromo(0);
        setLimitSellerPromo(0);
      }

      setLoading(false);
      setIsSending(false);
      toggleDoneFetchPromo({ isDoneFetchPromo: true });
    },

    [isSending, redeemCode, redeemApplyResult],
  );

  const handleSearchButton = (event) => {
    if (event.keyCode === 13) {
      searchPromo(text, true);
      event.preventDefault();
    }
  };
  // const nextFetch = () => {
  //   setOffset(offset + 20);
  //   // !totalList -> api k trả ra ngừng infinity scroll load
  //   if (promoList?.length === 0 || promoList?.length >= totalList || !totalList) {
  //     setIsHasMore(false);
  //   }
  // };
  useEffect(() => {
    if (visible) searchPromo(text);
  }, [visible]);

  const handleClickSearch = () => {
    searchPromo(text, true);
  };

  // TODO: always have 2 items in first time, when showMore, if firstTime + 3 to equal 5, not firstTime + 5
  const handleFetchMore = (type) => {
    if (type === ENUM_SECTION_PROMO.SELLER) {
      setSellerFetching(true);
      if (limitSellerPromo >= totalSellerPromo) {
        const prevSellerPromo = sellerPromoList?.map((item) => item).splice(0, 2);
        setSellerPromoShowMore(prevSellerPromo);
        setLimitSellerPromo(2);
      } else {
        const nextSellerPromo = sellerPromoList?.map((item) => item).splice(0, limitSellerPromo === 2 ? limitSellerPromo + 3 : limitSellerPromo + 5);
        setSellerPromoShowMore(nextSellerPromo);
        setLimitSellerPromo((prev) => (prev === 2 ? prev + 3 : prev + 5));
      }
      setSellerFetching(false);
    }
  };

  const handleSetMarketRefetch = () => setSectionPromoRefetch(ENUM_SECTION_PROMO.MARKETPLACE);
  const handleSetSelelrRefetch = () => setSectionPromoRefetch(ENUM_SECTION_PROMO.SELLER);

  useEffect(() => {
    // update tempPrice on delete outside promo list modal
    if (priceIncludePromo !== initialPriceIncludePromo) {
      setInitialPriceIncludePromo(priceIncludePromo);
    }
  }, [priceIncludePromo]);

  return (
    <Modal className={styles.modalContainer} open={visible} {...restProps} id="modalPromoList">
      {loading ? (
        <Grid className={clsx(styles.confirm_modal_wrap, loading && styles.wrapModalLoad)}>
          <Grid className={clsx(styles.modal_title, loading && styles.wrapTitleLoad)}>
            <Typography data-test="promo-text-modal" className={styles.modalVoucherList_title}>
              {modalVoucherTitle}
            </Typography>
          </Grid>
          <Grid className={styles.loadLogo}>
            <LoadingBM />
          </Grid>
        </Grid>
      ) : (
        <Grid className={styles.confirm_modal_wrap}>
          {/* search input and title */}
          <Grid container className={styles.modal_title} justifyContent="space-between" alignItems="center">
            <Typography data-test="promo-text-modal" className={styles.modalVoucherList_title}>
              {modalVoucherTitle}
            </Typography>
            <CloseIcon className={styles.close} onClick={onClose} data-test="ic-close-modal" />
          </Grid>
          <Box display="flex" flexDirection="column" width="100%" padding="12px 20px" gridGap={12} bgcolor="#f5f5f5">
            {sellerPromoList?.length > 0 && marketplacePromoList?.length > 0 ? (
              <div className={styles.notifySelectVoucher}>Bạn có thể chọn nhiều hơn 1 mã giảm giá</div>
            ) : null}
            <InputSearchVoucher
              isMobile={isMobile}
              handleRemoveText={handleRemoveText}
              value={text}
              onChange={handleChangeText}
              handleSearchButton={handleSearchButton}
              handleClickSearch={handleClickSearch}
            />
          </Box>
          <div
            className={clsx(
              styles.coupon_list_wrapper,
              isLoadingSearch ? styles.loadingWithSearch_wrapper : null,
              false ? styles.guideCartOverflow : null,
            )}
            id="marketPromoSection"
          >
            {/* loading when check/uncheck promo */}
            {isOnChecking ? (
              <div className={styles.loadingListPromo}>
                <Typography className={styles.loadingIdicator}>
                  <LoadingBM />
                </Typography>
              </div>
            ) : null}
            {/* all section have empty promo */}
            {!isLoadingSearch && marketplacePromoList?.length === 0 && sellerPromoList?.length === 0 ? (
              <div className={styles.not_yet}>
                <span data-test="modal-not-promo-text">{text && text !== '' ? 'Chưa có mã nào hợp lệ' : 'Chưa có mã'}</span>
              </div>
            ) : null}
            {/* loading search */}
            {isLoadingSearch ? (
              <Grid className={clsx(styles.confirm_modal_wrap)}>
                <Grid className={styles.loadingCentral}>
                  <LoadingBM />
                </Grid>
              </Grid>
            ) : null}
            {/* seller promo codes */}
            {!isLoadingSearch && sellerPromoList?.length > 0 ? (
              <div className={clsx(styles.counpon_list)} style={{ paddingBottom: 12 }} id="sellerSectionPromoList">
                <div className={styles.sectionVoucher_sticky}>
                  <Typography className={styles.sectionVoucher_title}>Mã giảm giá từ nhà bán hàng</Typography>
                </div>
                <Grid
                  container
                  className={clsx(
                    styles.voucherList_container,
                    sellerPromoList?.length === 0 ? styles.displayNone : null,
                    !text && limitSellerPromo < totalSellerPromo && limitSellerPromo < 2 ? styles.displayHalfPromo : null,
                  )}
                >
                  {sellerPromoShowMore?.map((voucher) => (
                    <Grid className={styles.coupon_card_grid} item key={voucher?.id} style={{ width: '100%' }} data-test="modal-item-promo">
                      <NewCartCouponCardV2
                        {...voucher}
                        redeemCode={redeemCode}
                        handleChangePromo={handleChangePromo}
                        subPrice={subPrice}
                        accountId={accountId}
                        customerID={customerID}
                        currentPage={currentPage}
                        isMobile={isMobile}
                        redeemApplyResult={redeemApplyResult}
                        onClosePromoList={onClose}
                        setOnChecking={setOnChecking}
                        setTriggerRefetchPromo={setTriggerRefetchPromo}
                        handleSectionPromoRefetch={handleSetSelelrRefetch}
                        priceIncludePromo={priceIncludePromo}
                        tempPriceIncludePromo={initialPriceIncludePromo}
                        setTempPriceIncludePromo={setInitialPriceIncludePromo}
                      />
                    </Grid>
                  ))}
                </Grid>
                {!text && sellerPromoList?.length > 2 ? (
                  <div className={styles.buttonCollapse}>
                    <SeeMoreBtn
                      type={ENUM_SECTION_PROMO.SELLER}
                      handleFetchMore={() => handleFetchMore(ENUM_SECTION_PROMO.SELLER)}
                      isFetching={isSellerFetching}
                      isReverse={limitSellerPromo >= totalSellerPromo}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* divider */}
            {!isLoadingSearch && marketplacePromoList?.length > 0 && sellerPromoList?.length > 0 ? <div className={styles.newDivider} /> : null}
            {/* marketplace promo codes */}
            {!isLoadingSearch && marketplacePromoList?.length > 0 ? (
              <div className={clsx(styles.counpon_list)} style={{ paddingBottom: 12 }} id="marketSectionPromoList">
                <div className={styles.sectionVoucher_sticky}>
                  <Typography className={styles.sectionVoucher_title}>Mã giảm giá từ thuocsi.vn</Typography>
                </div>
                <Grid container className={clsx(styles.voucherList_container, marketplacePromoList?.length === 0 ? styles.displayNone : null)}>
                  {marketplacePromoList?.map((voucher, idx) => (
                    <div
                      key={voucher?.id}
                      style={{ width: '100%', marginBottom: 12 }}
                      ref={idx === 0 ? availablePromoSectionRef : null}
                      id={!voucher?.errorMessage ? `section__availablePromo_${idx}` : voucher?.errorMessage ? `section__disablePromo_${idx}` : ''}
                    >
                      <Grid
                        className={styles.coupon_card_grid}
                        item
                        key={voucher?.id}
                        style={{ width: '100%', paddingBottom: isOnGuide && 0 }}
                        data-test="modal-item-promo"
                      >
                        <NewCartCouponCardV2
                          {...voucher}
                          redeemCode={redeemCode}
                          handleChangePromo={handleChangePromo}
                          subPrice={subPrice}
                          accountId={accountId}
                          customerID={customerID}
                          currentPage={currentPage}
                          isMobile={isMobile}
                          redeemApplyResult={redeemApplyResult}
                          onClosePromoList={onClose}
                          setOnChecking={setOnChecking}
                          setTriggerRefetchPromo={setTriggerRefetchPromo}
                          handleSectionPromoRefetch={handleSetMarketRefetch}
                          priceIncludePromo={priceIncludePromo}
                          idx={idx}
                          tempPriceIncludePromo={initialPriceIncludePromo}
                          setTempPriceIncludePromo={setInitialPriceIncludePromo}
                        />
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </div>
            ) : null}
            {/* divider */}
            {!isLoadingSearch && marketplacePromoList?.length > 0 && sellerPromoList?.length > 0 ? <div className={styles.newDivider} /> : null}
          </div>
          <Box className={styles.modal_footer}>
            {redeemApplyResult?.filter((redeem) => redeem?.canUse)?.length > 0 ? (
              <Typography variant="body2">{redeemApplyResult?.filter((redeem) => redeem?.canUse)?.length} Mã giảm giá đã được chọn</Typography>
            ) : null}
            <Button className={styles.modalButtonClose} onClick={onClose}>
              Đồng ý
            </Button>
          </Box>
        </Grid>
      )}
    </Modal>
  );
}

const SeeMoreBtn = ({ isFetching, handleFetchMore, type = '', isReverse = false }) => {
  return (
    <Button className={styles.btnSeeMoreItem} onClick={() => handleFetchMore(type)}>
      {isFetching ? (
        <CircularProgress color="primary" size={24} />
      ) : (
        <>
          {isReverse ? 'Ẩn' : 'Xem thêm'}{' '}
          {isReverse ? <ExpandLess style={{ width: 16, height: 16 }} /> : <ExpandMore style={{ width: 16, height: 16 }} />}
        </>
      )}
    </Button>
  );
};

export default memo(PromoListModalV2);
