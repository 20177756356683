import { ENUM_VOUCHER_ACTION_STATUS } from "./interface";

export const conditionPopupTitle: Record<string, {label: string, value: string}> = {
    code: {
        label: "Mã sử dụng",
        value: "code",
    },
    endTime: {
        label: "Hạn sử dụng",
        value: "endTime",
    },
    description: {
        label: "Nội dung",
        value: "description",
    },
    conditionDescription: {
        label: "Điều kiện sử dụng",
        value: "conditionDescription",
    },
    pointExchange: {
        label: "Điểm đổi",
        value: "pointExchange"
    },
    promoDetail: {
        label: "Chi tiết ưu đãi",
        value: 'detailPromo'
    },
    applyCondDesc: {
        label: 'Điều kiện áp dụng',
        value: 'applyCondDesc'
    },
    sellerStore: {
        label: "Trang NBH",
        value: "linkToStore"
    }
}


export const VoucherConditionTextButton: Record<ENUM_VOUCHER_ACTION_STATUS, string> = {
    [ENUM_VOUCHER_ACTION_STATUS.AVAILABLE]: 'Sử dụng ngay',
    [ENUM_VOUCHER_ACTION_STATUS.INVALID]: 'Mua thêm',
    [ENUM_VOUCHER_ACTION_STATUS.DISABLED]: 'Sử dụng ngay',
    [ENUM_VOUCHER_ACTION_STATUS.INUSE]: 'Đang sử dụng',
};

export const CartVoucherConditionTextButton: Record<ENUM_VOUCHER_ACTION_STATUS, string> = {
    [ENUM_VOUCHER_ACTION_STATUS.AVAILABLE]: "Chọn mã",
    [ENUM_VOUCHER_ACTION_STATUS.INVALID]: 'Chọn mã',
    [ENUM_VOUCHER_ACTION_STATUS.DISABLED]: 'Chọn mã',
    [ENUM_VOUCHER_ACTION_STATUS.INUSE]: "Đã chọn",
}

export const contentGuild = [
    {
        stt: 1,
        title: 'Đặt hàng',
        content: 'Vào trang đặt hàng nhanh hoặc sản phẩm để đặt hàng',
    },
    {
        stt: 2,
        title: 'Vào giỏ hàng',
        content: 'Vào trang giỏ hàng. Nhấn vào “Dùng mã giảm giá”',
    },
    {
        stt: 3,
        title: 'Dùng mã',
        content: 'Nhập mã muốn dùng ô tìm kiếm hoặc chọn trong danh sách mã. Sau đó nhấn vào nút “Dùng ngay”',
    },
];

export const questionGuild = [
    {
        title: '1. Làm sao để có điểm tích lũy?',
        image: '/images/promoCode/question_bonus_1.1_image.png',
    },
    {
        title: '2. Hướng dẫn về trạng thái của mã giảm giá',
        image: '/images/promoCode/question_1.1_image.png',
    },
    {
        title: '3. Hướng dẫn sử dụng mã trong trang giỏ hàng',
        image: '',
    },
    {
        title: '4. Cách xem lại mã đã sử dụng',
        image: '/images/promoCode/new_question_3_image.png' 
    },
    {
        title: '5. Các loại mã giảm giá đang được áp dụng trên thuocsi.vn có mã nào?',
        image: '',
    },
    {
        title: '6. Có mã nhưng không sử dụng được vì lý do nào?',
        image: '',
    },
    {
        title: '7. Nếu đơn hàng bị hủy, có được hoàn lại mã giảm giá không?',
        image: '',
    },
    {
        title: '8. Nếu hủy đơn hàng có được sử dụng lại mã giảm giá không?',
        image: '',
    }
];

const tempSelectVoucher = {
    code: '',
    conditionDescription: '',
    description: '',
    displayName: '',
    endTime: '',
    groupCode: '',
    maxUsage: 0,
    promotionName: '',
    publicTime: '',
    startTime: '',
    voucher: {
      appliedCustomers: [],
      code: '',
      endTime: '',
      promotion: {
        description: '',
      },
      promotionName: '',
      startTime: '',
    },
    voucherId: 0,
    usageTotal: 0,
    gifts: [],
  };

  export const REFERRAL_ERROR_CODE_MESSAGE: Record<string, string> = {
    REFER_CODE_NOT_EXIST: 'Mã giới thiệu không tồn tại',
    ACTION_NOT_FOUND: 'Bạn không được ủy quyền để truy cập liên kết này, vui lòng liên hệ quản trị viên',
    PHONE_INVALID: 'Số điện thoại không xác thực',
    CUSTOMER_NOT_FOUND: 'Thông tin khách hàng không tìm thấy',
    PHONE_EXISTED: 'Số điện thoại đã được đăng ký, không thể gửi mã giới thiệu cho số điện thoại này',
    INVITATION_SENT: 'Bạn đã gửi thành công tin nhắn giới thiệu bạn mới đến số điện thoại này',
    FRIEND_NOT_FOUND: 'Bạn không thể gửi tin nhắn giới thiệu bạn mới đến số điện thoại này',
    FRIEND_EXPIRED: 'Bạn đã hết hạn để gửi tin nhắn giới thiệu bạn mới đến số điện thoại này',
    CAN_NOT_RESEND: 'Bạn không thể gửi lại tin nhắn. Trong vòng 3 giờ, bạn chỉ có thể gửi 1 tin nhắn',
    SUBMIT_INVALID: 'Bạn chỉ có thể gửi tin nhắn giới thiệu bạn mới tối đa là 5 lần',
    REFER_PHONE_INVALID: 'Số điện thoại này không hợp lệ',
    FRIEND_NOT_ACTIVE: 'Số điện thoại này ko có hiệu lực trên thuocsi.vn',
  };
  