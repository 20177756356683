/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
// import { palette } from 'constants/Colors';
import { ENUM_PROMOTION_ORAGANIZER, ENUM_VOUCHER_ACTION_STATUS, IGifts, IVoucher } from 'components/organisms/PromoCodesContainerV2/interface';
import useModal from 'hooks/useModal';
import { memo } from 'react';
import VoucherV2 from '../VoucherV2';
import styles from './styles.module.css';

interface IRedeemApplyResult {
  autoApply: boolean;
  canUse: boolean;
  code: string;
  discountValue: number;
  gifts: IGifts | null;
  message: string;
}

interface Props {
  code: string;
  description: string;
  voucher: IVoucher;
  errorMessage: string;
  endTime: string;
  startTime: string;
  redeemCode: string[];
  handleChangePromo: (code: string[]) => void;
  subPrice: number;
  giftList: IGifts[];
  conditionDescription: string;
  canUse: boolean;
  discount: number;
  accountId: number;
  customerID: number;
  currentPage: number;
  isMobile: boolean;
  redeemApplyResult: IRedeemApplyResult[];
  checkValidate: boolean;
  status: string;
  promotionName: string;
  isUnlimited: boolean;
  availableQuantity: number;
  customerUsageTotal: number;
  isShowInfo: boolean;
  createdTime: string;
  displayName: string;
  shortName: string;
  actionStatus: ENUM_VOUCHER_ACTION_STATUS;
  actionLink: string;
  usageTotal: number;
  maxUsage: number;
  voucherImage?: string;
  tag?: string;
  onClosePromoList: () => void;
  setOnChecking: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerRefetchPromo: React.Dispatch<React.SetStateAction<number>>;
  handleSectionPromoRefetch: () => void;
  priceIncludePromo: number;
  promotionOrganizer: ENUM_PROMOTION_ORAGANIZER;
  linkToStore: string;
  sellerName: string;
  tempPriceIncludePromo: number;
  setTempPriceIncludePromo: React.Dispatch<React.SetStateAction<number>>;
  paymentMethod: string;
  paymentMethodName: string;
}

const NewCartCouponCardV2 = (props: Props) => {
  const {
    code = '',
    description = '',
    // voucher: { promotion = {} } = {},
    voucher,
    errorMessage = '',
    endTime: expiredDate,
    startTime,
    redeemCode = [],
    handleChangePromo,
    subPrice = 0,
    giftList = [],
    conditionDescription: newCondition = 'Áp dụng cho mọi đơn hàng.',
    canUse = false,
    discount = 0,
    accountId,
    customerID,
    currentPage,
    isMobile,
    redeemApplyResult = [],
    checkValidate = false,
    status,
    promotionName = '',
    isUnlimited = false,
    availableQuantity = 0,
    customerUsageTotal,
    isShowInfo = false,
    createdTime,
    displayName = '',
    shortName = '',
    actionStatus = ENUM_VOUCHER_ACTION_STATUS.AVAILABLE,
    actionLink = '',
    usageTotal = 0,
    maxUsage = 0,
    voucherImage = '',
    tag = '',
    onClosePromoList,
    setOnChecking,
    setTriggerRefetchPromo,
    handleSectionPromoRefetch,
    priceIncludePromo = 0,
    promotionOrganizer,
    linkToStore,
    sellerName,
    tempPriceIncludePromo = 0,
    setTempPriceIncludePromo,
    paymentMethod = '',
    paymentMethodName = '',
  } = props;
  const [filterByAutoApplyVoucher] = redeemApplyResult?.filter((item) => item.autoApply === true) || [];
  const countAutoApplyVoucher = filterByAutoApplyVoucher?.discountValue || 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [open, toggleOpen]: any = useModal();

  // ADD tracking, use for apply promo on dialog condition in cart page
  // const handleApplyVoucherDialog = () => {
  //   onClosePromoList();
  //   handleChangePromo([code]);
  //   gtag.clickUseDirectlyPromoCode(code);
  // };

  // eslint-disable-next-line no-constant-condition
  return (
    <Grid className={styles.coupon_card} id="coupon_card">
      <Grid container className={styles.coupon_cardCtn} direction="row" style={{ width: '100%' }}>
        <Grid className={styles.col_left} item container xs={12}>
          <Grid className={styles.col_left_inner} container>
            <VoucherV2
              isCart
              isMobile={isMobile}
              code={code}
              description={description}
              conditionDescription={newCondition}
              customerUsageTotal={customerUsageTotal}
              availableQuantity={availableQuantity}
              // isUnlimited={isUnlimited}
              endTime={expiredDate}
              canUse={canUse}
              discount={discount}
              errorMessage={errorMessage}
              // subPrice={subPrice}
              // countAutoApplyVoucher={countAutoApplyVoucher}
              // giftList={giftList}
              // checkValidate={checkValidate}
              // toggleOpen={toggleOpen}
              // isShowInfo={isShowInfo}
              displayName={displayName}
              shortName={shortName}
              voucherColor="#fff"
              actionStatus={actionStatus}
              actionLink={actionLink}
              // handleApplyVoucherDialog={handleApplyVoucherDialog}
              usageTotal={usageTotal}
              maxUsage={maxUsage}
              voucherImage={voucherImage}
              tag={tag}
              setOnChecking={setOnChecking}
              setTriggerRefetchPromo={setTriggerRefetchPromo}
              handleSectionPromoRefetch={handleSectionPromoRefetch} // catch user check/uncheck in which section Marketplace or Seller
              priceIncludePromo={priceIncludePromo}
              promotionOrganizer={promotionOrganizer}
              linkToStore={linkToStore}
              sellerName={sellerName}
              tempPriceIncludePromo={tempPriceIncludePromo}
              setTempPriceIncludePromo={setTempPriceIncludePromo}
              paymentMethod={paymentMethod}
              paymentMethodName={paymentMethodName}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(NewCartCouponCardV2);
