import { ENUM_PROMOCODE_TRACKING_TYPE } from 'components/organisms/PromoCodesContainerV3/interface';
import { MONITORING_COLLECTOR_TYPE } from 'constants/Enums';
import MonitorUtils from 'utils/MonitorUtils';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface ITracking {
  voucherID: string
  sellerCode: string
  storeCode: string
  sectionCode: string
  customerID: string
  trackingType: ENUM_PROMOCODE_TRACKING_TYPE
}

interface Actions {
  promoCodeTracking: ({ voucherID, sellerCode, sectionCode, trackingType }: ITracking) => void
}

type PromoCodeState = { actions: Actions }

const usePromoCodesV2States = create<PromoCodeState>()(
  devtools(() => ({
    actions: {
      promoCodeTracking: ({ voucherID, sellerCode, storeCode, sectionCode, customerID, trackingType }) => {
        let event;

        switch (trackingType) {
          case ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_VOUCHER_CONDITION: {
            event = MONITORING_COLLECTOR_TYPE.PROMOCODE_CLICK_VOUCHER_CONDITION;
            break;
          }
          case ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_BUY_MORE_BUTTON: {
            event = MONITORING_COLLECTOR_TYPE.PROMOCODE_CLICK_BUY_MORE_BUTTON;
            break;
          }
          case ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_APPLY_VOUCHER_BUTTON: {
            event = MONITORING_COLLECTOR_TYPE.PROMOCODE_CLICK_APPLY_VOUCHER_BUTTON;
            break;
          }
          default: {
            event = MONITORING_COLLECTOR_TYPE.PROMOCODE_STRANFOR_APPLYING_VOUCHER_BUTTON;
            break;
          }
        }

        const metaData = {
          customerID,
          voucherID,
          sellerCode,
          storeCode,
          sectionCode,
          platformName: 'web-desktop',
        }
    
        MonitorUtils.promoCodeClickEvent(event, metaData)
      },
    }
  }))
)

export const usePromoCodesV2Actions = () => usePromoCodesV2States((state) => state.actions)

export default usePromoCodesV2States