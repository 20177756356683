import create from 'zustand';

const useGuideCart = create((set) => ({
  isOnGuide: false,
  toggleGuide: (state) => set(state),
  isDoneFetchPromo: false,
  toggleDoneFetchPromo: (state) => set(state),
  isDoneFetchCartItems: false,
  toggleDontFetchCartItems: (state) => set(state),
  isOpenPromoList: false,
  toggleOpenPromoList: (state) => set(state),
}));

export default useGuideCart;
