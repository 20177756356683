import clsx from 'clsx';
import styles from './styles.module.css';

export const RIBBON_STATUS = {
  UP: 'up',
  DOWN: 'down',
};

export default function Ribbon({ status = RIBBON_STATUS.UP, percent = '63' }) {
  return (
    <div className={clsx(styles.ribbon, styles[`price_${status}`])}>
      <span className={clsx(styles.ribbon_percent, styles[`price_${status}`])}>{percent}%</span>
      <span className={styles.ribbon_status}>{status === RIBBON_STATUS.UP ? 'Tăng' : 'Giảm'}</span>
    </div>
  );
}
