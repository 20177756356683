/* eslint-disable react/jsx-no-undef */
import { Box, Button, Divider, Grid, IconButton, LinearProgress, Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import VoucherLogo from 'components/atoms/VoucherLogo';
import CountdownTimer from 'components/mocules/CountdownTimer';
import { VoucherConditionTextButton, conditionPopupTitle } from 'components/organisms/PromoCodesContainerV2/constants';
import { ENUM_PROMOTION_ORAGANIZER, ENUM_VOUCHER_ACTION_STATUS } from 'components/organisms/PromoCodesContainerV2/interface';
import { NewVoucherConditionTextButton } from 'components/organisms/PromoCodesContainerV3/constants';
import { ENUM_PROMOCODE_TRACKING_TYPE } from 'components/organisms/PromoCodesContainerV3/interface';
import { PROMOCODE_COPY_ICON } from 'constants/Images';
import { useAuth } from 'context/Auth';
import { useCart } from 'context/Cart/CartContext';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DateTimeUtils from 'utils/DateTimeUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { usePromoCodesV2Actions } from 'zustand-lib/usePromoCodesV2';
import useSellerSectionVoucher from 'zustand-lib/useSellerSectionVoucher';
import styles from './styles.module.css';


// const textButtonClose = 'Đóng';
const titlePopupCondition = 'Chi tiết mã giảm giá';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 10,
  position: 'relative',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#FFB951' : '#FFB951',
  },
}));


interface IPopupCondition {
  open: boolean;
  handleClose: () => void;
  code: string;
  description: string;
  endTime: string;
  conditionDescription: string;
  isMobile: boolean;
  voucherImage?: string;
  shortName?: string;
  displayName?: string;
  actionLink?: string;
  actionStatus?: ENUM_VOUCHER_ACTION_STATUS;
  isCart: boolean;
  // handleApplyVoucher?: () => void;
  setTriggerRefetchAllPromoList?: React.Dispatch<React.SetStateAction<number>>;
  promotionOrganizer: ENUM_PROMOTION_ORAGANIZER
  linkToStore?: string
  sellerName?: string
  isHideSection?: boolean // using for seller store page
  usageTotal?: number;
  maxUsage?: number;
  errorMessage?: string;
  isNewLayout?: boolean;
  isVoucherGift?: boolean;
  voucherID?: number;
  sectionCode?: string;
  storeCode?: string;
  sellerCode?: string;
}

const DialogConditionPromo = ({
  open,
  handleClose,
  code,
  description,
  endTime,
  conditionDescription,
  isMobile = false,
  voucherImage = '',
  shortName = '',
  displayName = '',
  actionLink = '',
  actionStatus = ENUM_VOUCHER_ACTION_STATUS.AVAILABLE,
  isCart = false,
  // handleApplyVoucher,
  setTriggerRefetchAllPromoList, // trigger re-fetch all promo list on promo-codes page
  promotionOrganizer,
  linkToStore = "",
  sellerName = "",
  isHideSection = false,
  usageTotal = 0,
  maxUsage = 0,
  errorMessage = "",
  isNewLayout = false,
  isVoucherGift = false,
  voucherID,
  sectionCode = '',
  storeCode = '',
  sellerCode = '',
}: IPopupCondition) => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }:any = useAuth()
  const { reloadDataCart, multiVoucherHandleChangePromoV2 } = useCart();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { toggleOffVoucherCondition }:any = useSellerSectionVoucher();
  const { promoCodeTracking } = usePromoCodesV2Actions();
  const handleBtnDialog = async (actionLink: string, actionStatus: ENUM_VOUCHER_ACTION_STATUS, code: string) => {
    // TODO: now onClick using promo, still make user in promo-pages, then re-load a promoList
    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE) {
      const res = await multiVoucherHandleChangePromoV2([code]);

      if (res.status === 'OK') {
        await reloadDataCart({
          cartRes: res,
          successMessage: 'Thêm mã giảm giá thành công',
          errorMessage: 'Thêm mã giảm giá thất bại',
          isReloadData: true,
        });
        if (setTriggerRefetchAllPromoList) {
          setTriggerRefetchAllPromoList((prev) => prev + 1);
        }
        
        promoCodeTracking({
          customerID: String(user?.customerID),
          voucherID: String(voucherID),
          sellerCode,
          storeCode,
          sectionCode,
          trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_APPLY_VOUCHER_BUTTON,
        });
        promoCodeTracking({
          customerID: String(user?.customerID),
          voucherID: String(voucherID),
          sellerCode,
          storeCode,
          sectionCode,
          trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_STRANFOR_APPLYING_VOUCHER_BUTTON,
        })

        if (isHideSection) {
          toggleOffVoucherCondition();
        }
      } else {
        switch (res.errorCode) {
          case 'NOT_FOUND': {
            NotifyUtils.error('Vui lòng thêm ít nhất một sản phẩm vào giỏ hàng và thử lại.');
            break;
          }
          case 'VOUCHER_CODE_INVALID': {
            NotifyUtils.error(res.message);
            break;
          }
          default: {
            NotifyUtils.error('Lỗi! Xin vui lòng kiểm tra lại.');
            break;
          }
        }
      }
    }

    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID) {
      promoCodeTracking({
        customerID: String(user?.customerID),
        voucherID: String(voucherID),
        sellerCode,
        storeCode,
        sectionCode,
        trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_BUY_MORE_BUTTON,
      });
      
      const pathNameActionLink = actionLink?.split('thuocsi.vn')?.pop() || '';
      if (pathNameActionLink === router.asPath) {
        router.reload()
      } else {
        router.push(actionLink);
      }
    }

    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE || actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED) {
      handleClose();
    }
  };

  if (isNewLayout) {
    return (
      <Box className={styles.guildlinePopup_wrapper}>
        <Modal open={open} onClose={handleClose}>
          <Box className={styles.guildlinePopup_container}>
            <Box className={styles.guildlinePopup_titleContainer}>
              <Typography variant="h6" style={{fontSize: '20px'}}>{titlePopupCondition}</Typography>
              <IconButton onClick={handleClose} style={{ padding: 0 }}>
                <Close style={{ fill: '#606060' }} />
              </IconButton>
            </Box>
            <Box className={styles.newGuildlineStep_container}>
              <Grid container>
                <Grid item md={3} className={styles.newVoucherLogo}>
                  <Box width={90} height={90} position="relative" display="flex" justifyContent="center" flexDirection="column" alignItems="center" border="1px solid #D9D9D9" borderRadius={80}>
                    <VoucherLogo source={promotionOrganizer} imageVoucher={voucherImage} isVoucherGift={isVoucherGift} />
                  </Box>
                  <Typography variant="body2" className={clsx(styles.newVoucherContent_title, styles.textCenter)}>
                    {shortName}
                  </Typography>
                </Grid>
                <Grid item md={9} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: 6}}>
                  <NewVoucherHead code={code} endTime={endTime} maxUsage={maxUsage} usageTotal={usageTotal} />
                </Grid>
              </Grid>
              <Divider />
              <NewVoucherContentPopupCondition
                conditionDescription={conditionDescription}
                displayName={displayName}
                sellerName={sellerName}
                linkToStore={linkToStore}
              />
            </Box>
            <Box textAlign="center" marginTop="12px">
              <span className={styles.newErrorMessage}>{errorMessage}</span>
            </Box>
            <Box style={{ textAlign: 'center', marginTop: '12px' }}>
              {
                isVoucherGift ? (
                  <Button
                    className={clsx(
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.newGiftPopupCondition_btnBuyMore,
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.newGiftPopupCondition_btnUseNow,
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.newGiftPopupCondition_btnUsing,
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.newGiftPopupCondition_btnDisabled, 
                      styles.newPopupCondition_btn
                    )}
                    onClick={() => handleBtnDialog(actionLink, actionStatus, code)}
                  >
                    <span>{NewVoucherConditionTextButton[actionStatus]}</span>
                  </Button>
                ) : (
                  <Button
                    className={clsx(
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.newPopupCondition_btnBuyMore,
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.popupCondition_btnUseNow,
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.newPopupCondition_btnUsing,
                      actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.newPopupCondition_btnDisabled,
                      styles.newPopupCondition_btn,
                    )}
                    onClick={() => handleBtnDialog(actionLink, actionStatus, code)}
                  >
                    <span>{NewVoucherConditionTextButton[actionStatus]}</span>
                  </Button>
                )
              }
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }

  return (
    <Box className={styles.guildlinePopup_wrapper}>
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.guildlinePopup_container}>
          <Box>
            <Box className={styles.guildlinePopup_titleContainer}>
              <Typography variant="h6">{titlePopupCondition}</Typography>
              <IconButton onClick={handleClose} style={{ padding: 0 }}>
                <Close style={{ fill: '#606060' }} />
              </IconButton>
            </Box>
          </Box>
          <Box className={styles.guildlineStep_container}>
            <Box>
              <Box width="80px" height="80px" margin="0 auto 8px" position="relative">
                <VoucherLogo source={promotionOrganizer} imageVoucher={voucherImage} isFull />
              </Box>
              <Typography variant="body2" className={clsx(styles.voucherContent_title, styles.textCenter)}>
                {shortName}
              </Typography>
            </Box>
            <ContentPopupCondition
              isMobile={isMobile}
              code={code}
              endTime={endTime}
              conditionDescription={conditionDescription}
              displayName={displayName}
              linkToStore={linkToStore}
              sellerName={sellerName}
            />
          </Box>
          {/* TODO: hide button action in dialog */}
          {!isCart ? (
            <Box style={{ textAlign: 'center', marginTop: '12px' }}>
              <Button
                className={clsx(
                  actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.popupCondition_btnBuyMore,
                  actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.popupCondition_btnUseNow,
                  actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.popupCondition_btnUsing,
                  actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.popupCondition_btnDisabled,
                  styles.popupCondition_btn,
                )}
                onClick={() => handleBtnDialog(actionLink, actionStatus, code)}
              >
                <span>{VoucherConditionTextButton[actionStatus]}</span>
              </Button>
            </Box>
          ) : (
            <Box textAlign="center">
              <Button
                className={styles.popupCondition_btnClose}
                onClick={handleClose}
              >
                <span>Đồng ý</span>
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

interface IContentPopupCondition {
  isMobile: boolean;
  code: string;
  endTime: string;
  conditionDescription: string;
  displayName: string;
  linkToStore?: string;
  sellerName?: string;
}

const ContentPopupCondition = ({ isMobile, code, endTime, conditionDescription, displayName, linkToStore, sellerName }: IContentPopupCondition) => {
  const [isCopied, setCopied] = useState(false);

  const copyToClipboard = () => {
    setCopied(true);
  };

  const checkTimeCountDown = () => {
    const threeDay = new Date().getTime() + 3 * 24 * 60 * 60 * 1000;
    const endVoucher = new Date(endTime).getTime();
    return endTime && threeDay > endVoucher ? (
      <span style={{ color: '#D4323B' }}>
        Còn <CountdownTimer prefix="" dealEndDay={endTime} style={{ display: 'inline-block' }} typeSpace=", " />
      </span>
    ) : (
      <Typography variant="body2" style={{ lineHeight: '18px', color: '#000', fontFamily: 'ggsm', fontSize: 14 }}>
        <span>{DateTimeUtils.getFormattedWithDate(new Date(endTime), 'DD/MM/YYYY')}</span>
      </Typography>
    );
  };

  return (
    <Box className={styles.contentPopupCondition_wrapper}>
      <Grid container>
        <Grid item md={4} xs={12} className={styles.contentPopupCondition_title}>
          {conditionPopupTitle.description.label}
        </Grid>
        <Grid item md={8} xs={12} className={styles.contentPopupCondition_value}>
          {displayName}
          <Divider className={styles.popupCondition_divider} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={4} xs={12} className={styles.contentPopupCondition_title}>
          {conditionPopupTitle.code.label}
        </Grid>
        <Grid item md={8} xs={12} className={styles.contentPopupCondition_value}>
          <Box className={styles.popupCondition_groupCodeBtn}>
            <span id="conditionPopup_voucherCode">{code}</span>
            <span style={{ display: 'inline-flex', flexWrap: 'nowrap', position: 'relative' }}>
              <CopyToClipboard text={code} onCopy={copyToClipboard}>
                <FileCopyOutlinedIcon className={styles.iconCopy} />
              </CopyToClipboard>
              <Typography variant="body2" style={{ position: 'absolute', right: '-1rem' }}>
                {isCopied ? <DoneIcon className={styles.iconCopyDone} /> : null}
              </Typography>
            </span>
          </Box>
          <Divider className={styles.popupCondition_divider} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={4} xs={12} className={styles.contentPopupCondition_title}>
          {conditionPopupTitle.endTime.label}
        </Grid>
        <Grid item md={8} xs={12} className={styles.contentPopupCondition_value}>
          {checkTimeCountDown()}
          <Divider className={styles.popupCondition_divider} />
        </Grid>
      </Grid>
      {
        sellerName ? (
          <Grid container>
            <Grid item md={4} xs={12} className={styles.contentPopupCondition_title}>
              {conditionPopupTitle.sellerStore.label}
            </Grid>
            <Grid item md={8} xs={12} className={styles.contentPopupCondition_value}>
              {
                linkToStore ? (
                  <a href={linkToStore} rel="noreferrer" className={styles.sellerRedirectLink}>
                    {sellerName}
                  </a>
                ) : (
                  <span>{sellerName}</span>
                )
              }
              <Divider className={styles.popupCondition_divider} />
            </Grid>
          </Grid>
        ) : null
      }
      <Grid container>
        <Grid item md={4} xs={12} className={styles.contentPopupCondition_title}>
          {conditionPopupTitle.conditionDescription.label}
        </Grid>
        <Grid item md={8} xs={12} className={styles.contentPopupCondition_value}>
          <div
            dangerouslySetInnerHTML={{ __html: conditionDescription || 'Áp dụng cho mọi đơn hàng' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DialogConditionPromo;


interface INewVoucherContentPopupCondition {
  conditionDescription: string;
  displayName: string;
  sellerName: string;
  linkToStore: string;
}

const NewVoucherContentPopupCondition = ({ conditionDescription, displayName, sellerName, linkToStore }: INewVoucherContentPopupCondition) => {
  return (
    <Box display="flex" flexDirection="column" gridGap="12px">
      <Grid container>
        <Box className={styles.newContentPopupCondition_title}>
          {conditionPopupTitle.description.label}
        </Box>
        <Box className={styles.newContentPopupCondition_value}>
          {displayName}
        </Box>
      </Grid>
      {
        sellerName ? (
          <Grid container>
            <Box className={styles.newContentPopupCondition_title}>
              {conditionPopupTitle.sellerStore.label}
            </Box>
            <Box className={styles.newContentPopupCondition_value}>
              {
                linkToStore ? (
                  <a href={linkToStore} rel="noreferrer" className={styles.sellerRedirectLink}>
                    {sellerName}
                  </a>
                ) : (
                  <span>{sellerName}</span>
                )
              }
            </Box>
          </Grid>
        ) : null
      }
      <Grid container>
        <Box className={styles.newContentPopupCondition_title}>
          {conditionPopupTitle.conditionDescription.label}
        </Box>
        <Box className={styles.newContentPopupCondition_value}>
          <div 
            className={styles.newConditionDescription}
            dangerouslySetInnerHTML={{ __html: conditionDescription || 'Áp dụng cho mọi đơn hàng' }}
          />
        </Box>
      </Grid>
    </Box>
  );
}

const NewVoucherHead = ({
  code = '', 
  endTime = '', 
  maxUsage = 0, 
  usageTotal = 0
}:{
  code: string, 
  endTime: string,
  maxUsage: number,
  usageTotal: number
}) => {
  const [isCopied, setCopied] = useState(false);

  const copyToClipboard = () => {
    setCopied(true);
  };

  const checkTimeCountDown = () => {
    const oneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    const endVoucher = new Date(endTime).getTime();
    return endTime && oneDay > endVoucher ? (
      <span style={{ color: '#D4323B' }}>
        Còn <CountdownTimer prefix="" dealEndDay={endTime} style={{ display: 'inline-block' }} typeSpace=", " />
      </span>
    ) : (
      <Typography variant="body2" style={{ lineHeight: '24px', color: '#000', fontFamily: 'ggsr', fontSize: 16 }}>
        <span>{DateTimeUtils.getFormattedWithDate(new Date(endTime), 'DD/MM/YYYY')}</span>
      </Typography>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box className={styles.newVoucherHeadContent_title}>
          {conditionPopupTitle.code.label}:
        </Box>
        &nbsp;
        <Box className={styles.newVoucherHeadContent_value} flex={1}>
          <Box className={styles.popupCondition_groupCodeBtn} style={{justifyContent: 'space-between'}}>
            <span id="conditionPopup_voucherCode">{code}</span>
            <span style={{ display: 'inline-flex', flexWrap: 'nowrap', position: 'relative' }}>
              <CopyToClipboard text={code} onCopy={copyToClipboard}>
                <Image src={PROMOCODE_COPY_ICON} width={20} height={20} alt="copy" className={styles.iconCopy} />
              </CopyToClipboard>
              <Typography variant="body2" style={{ position: 'absolute', right: '-1rem' }}>
                {isCopied ? <DoneIcon className={styles.iconCopyDone} /> : null}
              </Typography>
            </span>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={styles.voucherBag_voucherHeadContent_title}>
          {conditionPopupTitle.endTime.label}:
        </Box>
        &nbsp;
        <Box className={styles.newVoucherHeadContent_value}>
          {checkTimeCountDown()}
        </Box>
      </Box>
      {maxUsage > 1 && (
        <Box width="137px" position="relative" margin="4px 0">
          <BorderLinearProgress variant="determinate" value={Math.floor(((usageTotal || 0) * 100) / maxUsage) || 0} />
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translate(-50%, 10%)',
              fontSize: '10px',
              fontFamily: 'ggsm',
              lineHeight: '12px',
              whiteSpace: 'nowrap',
            }}
          >
            Đã dùng: {Math.floor(((usageTotal || 0) * 100) / maxUsage) || 0}%
          </span>
        </Box>
      )}
    </>
  )
}
