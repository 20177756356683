import { ENUM_PROMOTION_ORAGANIZER } from "components/organisms/PromoCodesContainerV2/interface";
import { PROMOCODES_EXTERNAL_SELLER_ICON, PROMOCODES_MEDX_ICON, PROMOCODES_TS_WITH_TEXT_ICON, PROMOCODE_DEFAULT_VOUCHER_GIFT } from "constants/Images";
import ImageFallback, { ImageFallbackStatic } from "utils/ImageFallback";
import { getLinkProxyCDN } from "utils/ImageUtils";

interface Props {
    source: ENUM_PROMOTION_ORAGANIZER,
    isFull?: boolean,
    imageVoucher?: string
    isVoucherGift?: boolean
}

const VoucherLogo = ({
    source,
    isFull = false,
    imageVoucher = '',
    isVoucherGift = false
}: Props) => {
  if (imageVoucher) {
    if (isFull) {
      return (
        <ImageFallback src={getLinkProxyCDN(imageVoucher)} fallbackSrc={getLinkProxyCDN(imageVoucher)} objectFit="contain" layout='fill' alt="logo voucher" />
        );
    }
    
    return <ImageFallback src={getLinkProxyCDN(imageVoucher)} fallbackSrc={getLinkProxyCDN(imageVoucher)} width={68} height={68} alt="logo voucher" />
  }

  if (isVoucherGift) {
    if (isFull) {
      return (
        <ImageFallback src={PROMOCODE_DEFAULT_VOUCHER_GIFT} fallbackSrc={getLinkProxyCDN(imageVoucher)} objectFit="contain" layout='fill' alt="logo voucher" />
        );
    }
    
    return <ImageFallback src={PROMOCODE_DEFAULT_VOUCHER_GIFT} fallbackSrc={getLinkProxyCDN(imageVoucher)} width={68} height={68} alt="logo voucher" />
  }

  switch(source) {
    case ENUM_PROMOTION_ORAGANIZER.SELLER_CENTER: {
      if (isFull) {
        return <ImageFallbackStatic src={PROMOCODES_EXTERNAL_SELLER_ICON} objectFit="contain" layout='fill' alt="logo external seller" />
      }

      return <ImageFallbackStatic src={PROMOCODES_EXTERNAL_SELLER_ICON} width={68} height={68} alt="logo external seller" /> 
    }
    case ENUM_PROMOTION_ORAGANIZER.INTERNAL_SELLER : {
      if (isFull) {
          return <ImageFallbackStatic src={PROMOCODES_MEDX_ICON} objectFit="contain" layout='fill' alt="logo internal seller" />
      }

      return <ImageFallbackStatic src={PROMOCODES_MEDX_ICON} width={68} height={68} alt="logo internal seller" /> 
    }
    default: {
      if (isFull) {
          return <ImageFallbackStatic src={PROMOCODES_TS_WITH_TEXT_ICON} objectFit="contain" layout='fill' alt="logo marketing" />
      }

      return <ImageFallbackStatic src={PROMOCODES_TS_WITH_TEXT_ICON} width={68} height={68} alt="logo marketing" />
    }
  }
}

export default VoucherLogo;