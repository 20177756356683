import { Box, GridSize } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { getFirst } from 'clients/Clients';
import clsx from 'clsx';
import { LinkComp } from 'components/atoms';
import VoucherLogo from 'components/atoms/VoucherLogo';
import CountdownTimer from 'components/mocules/CountdownTimer';
import { VoucherConditionTextButton } from 'components/organisms/PromoCodesContainerV2/constants';
import { ENUM_PROMOTION_ORAGANIZER, ENUM_VOUCHER_ACTION_STATUS, IMapOrderDetail } from 'components/organisms/PromoCodesContainerV2/interface';
import { VoucherBagVoucherConditionTextButton } from 'components/organisms/PromoCodesContainerV3/constants';
import { ENUM_PROMOCODE_TRACKING_TYPE, IGifts } from 'components/organisms/PromoCodesContainerV3/interface';
import { CART_VOUCHER_CHECKBOX_ICON, CART_VOUCHER_CHECKED_ICON, PROMOCODE_ERROR_MESSAGE_ICON } from 'constants/Images';
import { QUICK_ORDER } from 'constants/Paths';
import { useAuth } from 'context/Auth';
import { useCart } from 'context/Cart/CartContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useState } from 'react';
import DateTimeUtils from 'utils/DateTimeUtils';
import { formatCurrency } from 'utils/FormatNumber';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import NotifyUtils from 'utils/NotifyUtils';
import gtag from 'utils/gtag';
import useCartStates, { useCartActions } from 'zustand-lib/useCart';
import { useCheckoutActions } from 'zustand-lib/useCheckout';
import { usePromoCodesV2Actions } from 'zustand-lib/usePromoCodesV2';
import useSellerSectionVoucher from 'zustand-lib/useSellerSectionVoucher';
import DialogConditionPromo from '../DialogPromoV2';
import styles from './styles.module.css';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 10,
  position: 'relative',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#FFB951' : '#FFB951',
  },
}));

interface IVoucherV2 {
  isCart?: boolean;
  isVoucherUsed?: boolean;
  isMobile: boolean;
  code: string;
  description: string;
  displayName: string;
  conditionDescription: string;
  customerUsageTotal: number;
  availableQuantity: number;
  // isUnlimited?: boolean;
  endTime: string;
  canUse?: boolean;
  discount?: number;
  errorMessage?: string;
  // subPrice?: number;
  // countAutoApplyVoucher?: number;
  // giftList?: IGifts[];
  // checkValidate?: boolean;
  voucherColor?: string;
  tag?: string;
  shortName?: string;
  voucherImage?: string;
  actionStatus?: ENUM_VOUCHER_ACTION_STATUS;
  actionLink?: string;
  orderID?: number;
  mapOrderDetail?: IMapOrderDetail;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // toggleOpen?: any;
  // isShowInfo?: boolean;
  // handleApplyVoucherDialog?: () => void;
  usageTotal: number;
  maxUsage: number;
  setOnChecking?: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerRefetchPromo?: React.Dispatch<React.SetStateAction<number>>; // apply-for cart page
  handleSectionPromoRefetch?: () => void;
  priceIncludePromo?: number; // cart price with promo (auto and manual)
  setTriggerRefetchAllPromoList?: React.Dispatch<React.SetStateAction<number>>; // apply promo-codes page, onUse make reload list promo
  promotionOrganizer: ENUM_PROMOTION_ORAGANIZER;
  linkToStore?: string;
  sellerName?: string;
  isFullWidth?: boolean;
  isHideSection?: boolean;
  trackingClickCondition?: (id: number, code: string) => void;
  voucherId?: number;
  tempPriceIncludePromo: number;
  setTempPriceIncludePromo: React.Dispatch<React.SetStateAction<number>>;
  isNewLayout?: boolean;
  itemPerRow?: number;
  isSellerGroup?: boolean;
  gifts?: IGifts[]
  isProductVoucherSection?: boolean;
  sectionCode?: string;
  storeCode?: string;
  sellerCode?: string;
  paymentMethod?: string;
  paymentMethodName?: string;
}

const addToUniqueArray = (newString: string, oldString: string) => {
  let tempArray: string[] = [];
  const combinedString = oldString + ',' + newString;
  tempArray = combinedString.split(',').map((item) => item.trim());

  return Array.from(new Set(tempArray)).join(',') || '';
};

const VoucherV2 = ({
  isCart = false,
  isVoucherUsed = false, // use on promo-codes page, detect is promo used
  isMobile = false,
  code,
  description,
  displayName,
  conditionDescription,
  customerUsageTotal,
  availableQuantity,
  // isUnlimited = false,
  endTime,
  canUse, // use on cart page, check valid || invalid promo
  discount,
  errorMessage = '',
  // subPrice,
  // countAutoApplyVoucher,
  // giftList,
  // checkValidate,
  voucherColor = '#f4f7fc',
  tag = '',
  shortName = '',
  voucherImage = '',
  actionStatus = ENUM_VOUCHER_ACTION_STATUS.AVAILABLE,
  actionLink = '',
  orderID,
  mapOrderDetail,
  // toggleOpen,
  // isShowInfo,
  // handleApplyVoucherDialog,
  usageTotal = 0,
  maxUsage = 0,
  setOnChecking,
  setTriggerRefetchPromo,
  handleSectionPromoRefetch,
  priceIncludePromo = 0,
  setTriggerRefetchAllPromoList,
  promotionOrganizer,
  linkToStore = '',
  sellerName = '',
  isFullWidth = false, // fullWidth of voucher section
  isHideSection = false, // hide right drawer on seller store page
  trackingClickCondition,
  voucherId,
  tempPriceIncludePromo = 0,
  setTempPriceIncludePromo,
  isNewLayout = false,
  itemPerRow = 3,
  isSellerGroup = false,
  gifts = [],
  isProductVoucherSection = false,
  sectionCode = '',
  storeCode = '',
  sellerCode = '',
  paymentMethod: voucherPaymentMethod,
  paymentMethodName: voucherPaymentMethodName,
}: IVoucherV2): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isOpenPopupCondition, setOpenPopupCondition] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { toggleOnVoucherCondition, toggleOffVoucherCondition, isOpenVoucherCondition }:any = useSellerSectionVoucher();
  const isVoucherGift = gifts && gifts?.length > 0

  const handleOpenPopupCondition = () => {
    gtag.clickPromoCodeCondition(code, isCart);
    setOpenPopupCondition(true);
    if (isHideSection) {
      toggleOnVoucherCondition();
    }
    if (trackingClickCondition) {
      trackingClickCondition(voucherId as number, code);
    }
  };

  const handleClosePopupCondition = () => {
    setOpenPopupCondition(false);
    if (isHideSection) {
      toggleOffVoucherCondition();
    }
  };

  const handleClickVoucherDisabled = () => {
    // TODO: replace notify by toggle popup condition
    setOpenPopupCondition(true);
  };

  if (isCart) {
    return (
      <Grid item md={12} xs={12} style={{ position: 'relative', padding: '1px' }}>
        <Grid style={{ position: 'relative', overflow: 'hidden' }}>
          {tag && tag !== '' ? <div className={styles.voucher_quantityLimit}>{tag}</div> : null}
          <div className={styles.voucherLeftMissingPart} style={{ backgroundColor: voucherColor }} />
          <Grid className={clsx(styles.voucherItem_wrapper)}>
            {!canUse && errorMessage ? <div className={styles.disabledVoucher} onClick={handleClickVoucherDisabled} /> : null}
            <Grid container className={styles.voucherContainer_upper}>
              <Grid item md={10} xs={10} style={{ paddingRight: 8 }}>
                <Typography variant="body2" className={styles.voucherContent_title} noWrap>
                  {shortName}
                </Typography>
                <Typography style={{ fontSize: 14 }} variant="body2">
                  <div
                    className={clsx(clsx(isMobile ? styles.mobileVoucherContent_desc : styles.voucherContent_desc), styles.content_2Line)}
                    dangerouslySetInnerHTML={{ __html: displayName }}
                  />
                </Typography>
                {actionStatus !== ENUM_VOUCHER_ACTION_STATUS.INUSE ? (
                  <Grid
                    style={{
                      fontFamily: 'ggsr',
                      display: !canUse || discount === 0 ? 'none' : 'unset',
                      color: '#000',
                      fontSize: 14,
                      lineHeight: '18px',
                    }}
                  >
                    Đơn sau khi áp dụng:{' '}
                    <span style={{ color: '#D4323B', fontFamily: 'ggsm' }}>
                      {formatCurrency(Math.max((tempPriceIncludePromo as number) - (discount as number), 0))}
                    </span>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item md={2} xs={2} style={{ position: 'relative', marginTop: 15 }}>
                <VoucherLogo source={promotionOrganizer} imageVoucher={voucherImage} />
              </Grid>
            </Grid>
            <div className={styles.voucherDivider} />
            <Grid className={styles.voucherContainer_downer}>
              <Grid container>
                {!canUse && errorMessage ? <div className={styles.disabledVoucher} onClick={handleClickVoucherDisabled} /> : null}
                <ExpiredDateProgessBar expiredDate={endTime} usageTotal={usageTotal} maxUsage={maxUsage} isMobile={isMobile} />
                <ConditionAndUseNow
                  handleOpenCondition={handleOpenPopupCondition}
                  actionStatus={actionStatus}
                  actionLink={actionLink}
                  promoCode={code}
                  isCart
                  errorMessage={errorMessage}
                  setOnChecking={setOnChecking}
                  setTriggerRefetchPromo={setTriggerRefetchPromo}
                  handleSectionPromoRefetch={handleSectionPromoRefetch}
                  discount={discount}
                  tempPriceIncludePromo={tempPriceIncludePromo}
                  setTempPriceIncludePromo={setTempPriceIncludePromo}
                  voucherPaymentMethod={voucherPaymentMethod}
                  voucherPaymentMethodName={voucherPaymentMethodName}
                />
              </Grid>
            </Grid>
          </Grid>
          <div className={clsx(styles.voucherRightMissingPart)} style={{ backgroundColor: voucherColor }} />
        </Grid>
        {!canUse && errorMessage && (
          <Grid className={styles.errorMessage_buyMoreContainer_cart}>
            <Typography variant="body2">
              <ErrorOutline /> {errorMessage}
            </Typography>
          </Grid>
        )}
        <DialogConditionPromo
          isMobile={isMobile}
          open={isOpenPopupCondition}
          handleClose={handleClosePopupCondition}
          code={code}
          description={description}
          endTime={endTime}
          conditionDescription={conditionDescription}
          voucherImage={voucherImage}
          shortName={shortName}
          displayName={displayName}
          actionLink={actionLink}
          actionStatus={actionStatus}
          isCart={isCart}
          promotionOrganizer={promotionOrganizer}
          // handleApplyVoucher={handleApplyVoucherDialog}
          linkToStore={linkToStore}
          sellerName={sellerName}
        />
      </Grid>
    );
  }

  if (isNewLayout) {
    const calcItemPerRow = itemPerRow ? 12/itemPerRow : 4;
    return (
      <Grid item md={calcItemPerRow as GridSize} xs={12} className={styles.newVoucherWrapper}>
        <Box className={styles.newVoucherContainer}>
          {tag && tag !== '' ? <div className={styles.newVoucherTag}>{tag}</div> : null}
          <Grid className={clsx(styles.newVoucherItemWrapper, isVoucherGift ? styles.newVoucherItemGiftWrapper : '')}>
            <Grid container className={styles.newVoucherMainContainer}>
              <Grid item md={3} xs={3} className={clsx(styles.newVoucherLogo, isSellerGroup ? styles.newVoucherLogoSellerGroup : '')}>
                <Box display="flex" justifyContent="center">
                  <VoucherLogo source={promotionOrganizer} imageVoucher={voucherImage} isVoucherGift={isVoucherGift} />
                </Box>
                {isVoucherUsed ? (
                  <NewExpiredDateUsedTime expiredDate={endTime} />
                ) : (
                  <NewExpiredDateProgessBar expiredDate={endTime} />
                )}
              </Grid>
              <div className={styles.newVerticalDivider} />
              <Grid item md={9} xs={9} className={styles.newVoucherInfo}>
                <Box>
                  <Typography variant="body2" className={clsx(isVoucherUsed ? styles.voucherUsedContent_title : styles.newVoucherContent_title)} noWrap>
                    {shortName}
                  </Typography>
                  <Typography variant="body2">
                    <div className={clsx(styles.voucherContent_desc, styles.content_2Line)} dangerouslySetInnerHTML={{ __html: displayName }} />
                  </Typography>
                </Box>
                <Grid container>
                  {isVoucherUsed ? (
                    <NewRequireTotalOrder orderID={String(orderID)} mapOrderDetail={mapOrderDetail} />
                  ) : (
                    <NewConditionAndUseNow
                      handleOpenCondition={handleOpenPopupCondition}
                      actionStatus={actionStatus}
                      actionLink={actionLink}
                      promoCode={code}
                      setTriggerRefetchAllPromoList={setTriggerRefetchAllPromoList}
                      isVoucherGift={isVoucherGift}
                      voucherID={voucherId}
                      sectionCode={sectionCode}
                      storeCode={storeCode}
                      sellerCode={sellerCode}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <DialogConditionPromo
          isMobile={isMobile}
          open={isOpenPopupCondition}
          handleClose={handleClosePopupCondition}
          code={code}
          description={description}
          endTime={endTime}
          conditionDescription={conditionDescription}
          voucherImage={voucherImage}
          shortName={shortName}
          displayName={displayName}
          actionLink={actionLink}
          actionStatus={actionStatus}
          isCart={isCart}
          setTriggerRefetchAllPromoList={setTriggerRefetchAllPromoList}
          promotionOrganizer={promotionOrganizer}
          usageTotal={usageTotal}
          maxUsage={maxUsage}
          linkToStore={linkToStore}
          sellerName={sellerName}
          errorMessage={errorMessage}
          isNewLayout
          isVoucherGift={isVoucherGift}
          voucherID={voucherId}
          sectionCode={sectionCode}
          storeCode={storeCode}
          sellerCode={sellerCode}
        />
      </Grid>
    );
  }

  if (isProductVoucherSection) {
    return (
      <Grid item md={2} style={{display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center'}}>
        <Typography variant="body2" className={styles.newVoucherContent_title} noWrap style={{marginBottom: 12}}>
          {shortName}
        </Typography>
        <Typography variant="body2" style={{marginBottom: 12}}>
          <div className={clsx(styles.newVoucherContent_desc, styles.content_2Line)} dangerouslySetInnerHTML={{ __html: displayName }} />
        </Typography>
        <NewConditionAndUseNow
          handleOpenCondition={handleOpenPopupCondition}
          actionStatus={actionStatus}
          actionLink={actionLink}
          promoCode={code}
          setTriggerRefetchAllPromoList={setTriggerRefetchAllPromoList}
          isProductVoucherSection
          isVoucherGift={isVoucherGift}
          voucherID={voucherId}
          sectionCode={sectionCode}
          storeCode={storeCode}
          sellerCode={sellerCode}
        />

        <DialogConditionPromo
          isMobile={isMobile}
          open={isOpenPopupCondition}
          handleClose={handleClosePopupCondition}
          code={code}
          description={description}
          endTime={endTime}
          conditionDescription={conditionDescription}
          voucherImage={voucherImage}
          shortName={shortName}
          displayName={displayName}
          actionLink={actionLink}
          actionStatus={actionStatus}
          isCart={isCart}
          setTriggerRefetchAllPromoList={setTriggerRefetchAllPromoList}
          promotionOrganizer={promotionOrganizer}
          usageTotal={usageTotal}
          maxUsage={maxUsage}
          linkToStore={linkToStore}
          sellerName={sellerName}
          errorMessage={errorMessage}
          isNewLayout
          isVoucherGift={isVoucherGift}
        />
      </Grid>
    )
  }

  return (
    <Grid item md={isFullWidth ? 12 : 4} xs={12} style={{ position: 'relative' }}>
      <Grid style={{ position: 'relative', overflow: 'hidden' }}>
        {tag && tag !== '' ? <div className={styles.voucher_quantityLimit}>{tag}</div> : null}
        <div className={styles.voucherLeftMissingPart} style={{ backgroundColor: voucherColor }} />
        <Grid className={styles.voucherItem_wrapper}>
          <Grid container className={styles.voucherContainer_upper}>
            <Grid item md={9} xs={9}>
              <Typography variant="body2" className={styles.voucherContent_title} noWrap>
                {shortName}
              </Typography>
              <Typography variant="body2">
                <div className={clsx(styles.voucherContent_desc, styles.content_2Line)} dangerouslySetInnerHTML={{ __html: displayName }} />
              </Typography>
            </Grid>
            <Grid item md={3} xs={3} style={{ textAlign: 'right', position: 'relative', marginTop: 15 }}>
              <VoucherLogo source={promotionOrganizer} imageVoucher={voucherImage} />
            </Grid>
          </Grid>
          <div className={styles.voucherDivider} />
          <Grid className={styles.voucherContainer_downer}>
            <Grid container className={clsx(isMobile && styles.mobileVoucherContainerGroup_downer)}>
              {isVoucherUsed ? (
                <ExpiredDateUsedTime expiredDate={endTime} orderID={orderID as number} />
              ) : (
                <ExpiredDateProgessBar expiredDate={endTime} usageTotal={customerUsageTotal} maxUsage={availableQuantity} isMobile={isMobile} />
              )}
              {isVoucherUsed ? (
                <RequireTotalOrder orderID={String(orderID)} mapOrderDetail={mapOrderDetail} />
              ) : (
                <ConditionAndUseNow
                  handleOpenCondition={handleOpenPopupCondition}
                  actionStatus={actionStatus}
                  actionLink={actionLink}
                  promoCode={code}
                  setTriggerRefetchAllPromoList={setTriggerRefetchAllPromoList}
                  // handleApplyVoucher={handleApplyVoucher}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className={styles.voucherRightMissingPart} style={{ backgroundColor: voucherColor }} />
      </Grid>

      {!isVoucherUsed && errorMessage && (
        <Grid className={styles.errorMessage_buyMoreContainer}>
          <Typography variant="body2">
            <ImageFallbackStatic src={PROMOCODE_ERROR_MESSAGE_ICON} width={16} height={16} alt="error icon" /> {errorMessage}
          </Typography>
        </Grid>
      )}
      <DialogConditionPromo
        isMobile={isMobile}
        open={isOpenPopupCondition}
        handleClose={handleClosePopupCondition}
        code={code}
        description={description}
        endTime={endTime}
        conditionDescription={conditionDescription}
        voucherImage={voucherImage}
        shortName={shortName}
        displayName={displayName}
        actionLink={actionLink}
        actionStatus={actionStatus}
        isCart={isCart}
        setTriggerRefetchAllPromoList={setTriggerRefetchAllPromoList}
        promotionOrganizer={promotionOrganizer}
        // handleApplyVoucher={handleApplyVoucher}
        linkToStore={linkToStore}
        sellerName={sellerName}
        isHideSection={isHideSection}
        isVoucherGift={isVoucherGift}
        isNewLayout
      />
    </Grid>
  );
};

const ExpiredDateProgessBar = ({
  expiredDate,
  usageTotal = 0,
  maxUsage = 0,
  isMobile = false,
}: {
  expiredDate: string;
  usageTotal: number;
  maxUsage: number;
  isMobile: boolean;
}) => {
  // countdown expire day
  const checkTimeCountDown = () => {
    const threeDay = new Date().getTime() + 3 * 24 * 60 * 60 * 1000;
    const endVoucher = new Date(expiredDate).getTime();
    return expiredDate && threeDay > endVoucher ? (
      <span style={{ color: '#D4323B' }}>
        Còn <CountdownTimer prefix="" dealEndDay={expiredDate} style={{ display: 'inline-block' }} typeSpace=", " />
      </span>
    ) : (
      <>
        HSD: <span>{DateTimeUtils.getFormattedWithDate(new Date(expiredDate), 'DD/MM/YYYY')}</span>
      </>
    );
  };
  return (
    <Grid item md={4} sm={4} className={styles.expiredDateBarWrapper}>
      <Typography variant="body2" style={{ lineHeight: '14px', color: '#000', fontFamily: 'ggsm', fontSize: isMobile ? 11 : 12 }}>
        {checkTimeCountDown()}
      </Typography>
      {maxUsage > 1 && (
        <Grid style={{ width: '100%', marginRight: 1, position: 'relative' }}>
          <BorderLinearProgress variant="determinate" value={Math.floor(((usageTotal || 0) * 100) / maxUsage) || 0} />
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translate(-50%, 10%)',
              fontSize: '10px',
              fontFamily: 'ggsm',
              lineHeight: '12px',
              whiteSpace: 'nowrap',
            }}
          >
            Đã dùng: {Math.floor(((usageTotal || 0) * 100) / maxUsage) || 0}%
          </span>
        </Grid>
      )}
    </Grid>
  );
};

const ConditionAndUseNow = ({
  handleOpenCondition,
  actionStatus = ENUM_VOUCHER_ACTION_STATUS.AVAILABLE,
  actionLink,
  promoCode,
  isCart = false,
  errorMessage = '',
  setOnChecking,
  setTriggerRefetchPromo,
  handleSectionPromoRefetch,
  setTriggerRefetchAllPromoList,
  discount = 0,
  tempPriceIncludePromo = 0,
  setTempPriceIncludePromo,
  voucherPaymentMethod,
  voucherPaymentMethodName,
}: {
  handleOpenCondition: () => void;
  actionStatus: ENUM_VOUCHER_ACTION_STATUS;
  actionLink: string;
  promoCode: string;
  isCart?: boolean;
  errorMessage?: string;
  setOnChecking?: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerRefetchPromo?: React.Dispatch<React.SetStateAction<number>>;
  handleSectionPromoRefetch?: () => void;
  setTriggerRefetchAllPromoList?: React.Dispatch<React.SetStateAction<number>>;
  discount?: number;
  tempPriceIncludePromo?: number;
  setTempPriceIncludePromo?: React.Dispatch<React.SetStateAction<number>>;
  voucherPaymentMethod?: string
  voucherPaymentMethodName?: string
}) => {
  const router = useRouter();
  const {
    reloadDataCart,
    multiVoucherHandleRemovePromoV2,
    multiVoucherHandleChangePromoV2,
    updateCart,
    toggleOnCheckingPromo,
    setTempRedeemApplyResult,
    paymentMethod,
  } = useCart();
  const {
    updateRedeemApplyResult,
    updatePromosRemoved,
    updateNextUsingPromo,
  } = useCartActions();
  const {
    promosRemoved,
  } = useCartStates();
  const {
    togglePopup,
    updatePopupPaymentType,
  } = useCheckoutActions()
  const redirectToLink = async (actionLink: string, actionStatus: ENUM_VOUCHER_ACTION_STATUS, promoCode: string) => {
    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE) {
      const res = await multiVoucherHandleChangePromoV2([promoCode]);

      if (res.status === 'OK') {
        await reloadDataCart({
          cartRes: res,
          successMessage: 'Thêm mã giảm giá thành công',
          errorMessage: 'Thêm mã giảm giá thất bại',
          isReloadData: true,
        });
        if (setTriggerRefetchAllPromoList) {
          setTriggerRefetchAllPromoList((prev) => prev + 1);
        }
      } else {
        switch (res.errorCode) {
          case 'NOT_FOUND': {
            NotifyUtils.error('Vui lòng thêm ít nhất một sản phẩm vào giỏ hàng và thử lại.');
            break;
          }
          case 'VOUCHER_CODE_INVALID': {
            NotifyUtils.error(res.message);
            break;
          }
          default: {
            NotifyUtils.error('Lỗi! Xin vui lòng kiểm tra lại.');
            break;
          }
        }
      }
    }

    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID) {
      const pathNameActionLink = actionLink?.split('thuocsi.vn')?.pop() || '';
      if (pathNameActionLink === router.asPath) {
        router.reload();
      } else {
        router.push(actionLink);
      }
    }
  };

  const applyVoucher = async (actionStatus: ENUM_VOUCHER_ACTION_STATUS, promoCode: string, voucherPaymentMethod: string, voucherPaymentMethodName: string ) => {
    if (actionStatus !== ENUM_VOUCHER_ACTION_STATUS.INUSE) {

      if (setOnChecking) {
        setOnChecking(true);
        toggleOnCheckingPromo(true);
      }
      const res = await multiVoucherHandleChangePromoV2([promoCode]);
      if (res.status === 'OK') {
        const { redeemApplyResult } = getFirst(res);
        setTempRedeemApplyResult(redeemApplyResult);
        updateRedeemApplyResult(redeemApplyResult);
        if (setTempPriceIncludePromo) {
          setTempPriceIncludePromo(Math.max(tempPriceIncludePromo - discount, 0));
        }

        gtag.clickUseDirectlyPromoCode(promoCode);

        if (handleSectionPromoRefetch) {
          handleSectionPromoRefetch();
        }
        if (setTriggerRefetchPromo) {
          setTriggerRefetchPromo((prev) => prev + 1);
        }
      } else {
        // When errorCode return is equal "INVALID_PAYMENT_METHOD", a confirmation window occures. When the user confirms, the popup will update their payment method and update the voucher.
        if (res.errorCode === 'INVALID_PAYMENT_METHOD') {
          updateNextUsingPromo({ code: promoCode, paymentMethod: voucherPaymentMethod, paymentMethodName: voucherPaymentMethodName });
          togglePopup(true);
          if (setOnChecking) {
            setOnChecking(false);
          }
          return;
        }
        NotifyUtils.error(res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !');
        if (setOnChecking) {
          setOnChecking(false);
          toggleOnCheckingPromo(false);
        }
      }
    }

    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE) {
      if (setOnChecking) {
        setOnChecking(true);
        toggleOnCheckingPromo(true);
      }
      const res = await multiVoucherHandleRemovePromoV2([promoCode]);
      if (res.status === 'OK') {
        // await updateCart({ cartRes: res, successMessage: 'Xoá mã giảm giá thành công' });
        const { redeemApplyResult } = getFirst(res);
        setTempRedeemApplyResult(redeemApplyResult);
        updateRedeemApplyResult(redeemApplyResult);
        // TODO: on remove promo, update to state use to call in cart API
        const formatPromosRemoved = promosRemoved === '' ? promoCode : addToUniqueArray(promoCode, promosRemoved);
        updatePromosRemoved(formatPromosRemoved);
        if (setTempPriceIncludePromo) {
          setTempPriceIncludePromo(Math.max(tempPriceIncludePromo + discount, 0));
        }

        if (handleSectionPromoRefetch) {
          handleSectionPromoRefetch();
        }
        if (setTriggerRefetchPromo) {
          setTriggerRefetchPromo((prev) => prev + 1);
        }
      } else {
        NotifyUtils.error(res.message || 'Đã có lỗi xảy ra, vui lòng thử lại !');
        if (setOnChecking) {
          setOnChecking(false);
          toggleOnCheckingPromo(false);
        }
      }
    }
  };

  if (isCart) {
    return (
      <Grid item md={8} sm={8}>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px 0 12px' }}>
          <Button style={{ color: '#0E1983', padding: '6px 8px', textTransform: 'unset', zIndex: 10 }} onClick={() => handleOpenCondition()}>
            <ErrorOutline style={{ width: '20px', height: '20px' }} />
            <Typography variant="body2" style={{ paddingLeft: 8, fontSize: 12, lineHeight: '18px' }}>
              Điều kiện
            </Typography>
          </Button>

          <Button
            className={clsx(
              actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE ? styles.cartVoucherBtn_inUse : styles.cartVoucherBtn_outUse,
              styles.cartVoucherBtn,
              errorMessage ? styles.cartVoucherBtn_disabled : null,
            )}
            disabled={errorMessage !== ''}
          >
            <Checkbox
              disabled={errorMessage !== ''}
              onChange={() => applyVoucher(actionStatus, promoCode, voucherPaymentMethod || '', voucherPaymentMethodName || '')}
              inputProps={{ 'aria-label': 'controlled' }}
              checked={actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE}
              icon={
                errorMessage ? (
                  <div className={styles.checkboxDisabled}>
                    <ImageFallbackStatic src={CART_VOUCHER_CHECKBOX_ICON} width={28} height={28} alt="checkbox icon" />
                  </div>
                ) : (
                  <ImageFallbackStatic src={CART_VOUCHER_CHECKBOX_ICON} width={28} height={28} alt="checkbox icon" />
                )
              }
              checkedIcon={
                errorMessage ? (
                  <div className={styles.checkboxDisabled}>
                    <ImageFallbackStatic src={CART_VOUCHER_CHECKBOX_ICON} width={28} height={28} alt="checkbox icon" />
                  </div>
                ) : (
                  <ImageFallbackStatic src={CART_VOUCHER_CHECKED_ICON} width={28} height={28} alt="checkbox icon" />
                )
              }
              style={{ padding: 0 }}
            />
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item md={8}>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
        <Button style={{ color: '#0E1983', padding: '6px 8px', textTransform: 'unset' }} onClick={() => handleOpenCondition()}>
          <ErrorOutline style={{ width: '20px', height: '20px' }} />
          <Typography variant="body2" style={{ paddingLeft: 8, fontSize: 12, lineHeight: '18px' }}>
            Điều kiện
          </Typography>
        </Button>

        <Button
          className={clsx(
            actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.voucherCondition_btnBuyMore,
            actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.voucherCondition_btnUseNow,
            actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.voucherCondition_btnUsing,
            actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.voucherCondition_btnDisabled,
            styles.voucherCondition_btn,
          )}
          onClick={() => {
            redirectToLink(actionLink, actionStatus, promoCode);
          }}
        >
          <span>{VoucherConditionTextButton[actionStatus]}</span>
        </Button>
      </Grid>
    </Grid>
  );
};

const ExpiredDateUsedTime = ({ expiredDate, orderID }: { expiredDate: string; orderID: number }) => {
  return (
    <Grid item md={7} xs={7} style={{ display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center' }}>
      <Grid style={{ width: '100%', marginRight: 1, position: 'relative' }}>
        <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'ggsm', fontSize: 12 }}>
          Đơn hàng:{' '}
          <Link target="_blank" href={`/my-order/${orderID}`}>
            {orderID}
          </Link>
        </Typography>
      </Grid>
      <Typography variant="body2" style={{ lineHeight: '14px', color: '#797979', fontFamily: 'ggsm', fontSize: 12 }}>
        Ngày sử dụng: {DateTimeUtils.getFormattedDate(new Date(expiredDate), 'DD/MM/YYYY')}{' '}
      </Typography>
    </Grid>
  );
};

const RequireTotalOrder = ({ orderID, mapOrderDetail }: { orderID?: string; mapOrderDetail?: IMapOrderDetail }) => {
  return (
    <Grid item md={5} xs={5}>
      <Grid style={{ textAlign: 'right' }}>
        <Typography variant="body2" className={styles.requireOrder_text}>
          Giá trị đơn
        </Typography>
        {orderID && mapOrderDetail?.[orderID] && (
          <Typography variant="body2" className={styles.requireOrder_price}>
            {formatCurrency(mapOrderDetail?.[orderID as keyof IMapOrderDetail]?.totalPrice || 0)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const NewExpiredDateProgessBar = ({
  expiredDate,
}: {
  expiredDate: string;
}) => {
  // countdown expire day
  const checkTimeCountDown = () => {
    const oneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    const endVoucher = new Date(expiredDate).getTime();
    return expiredDate && oneDay > endVoucher ? (
      <span style={{ color: '#D4323B', paddingLeft: '12px' }}>
        Còn <CountdownTimer prefix="" dealEndDay={expiredDate} style={{ display: 'inline-block' }} typeSpace=", " />
      </span>
    ) : (
      <>
        HSD: <span>{DateTimeUtils.getFormattedWithDate(new Date(expiredDate), 'DD/MM/YYYY')}</span>
      </>
    );
  };
  return (
    <Grid item md={4} sm={4} className={styles.expiredDateBarWrapper} style={{whiteSpace: 'nowrap'}}>
      <Typography variant="body2" className={styles.newCountDownTime}>
        {checkTimeCountDown()}
      </Typography>
    </Grid>
  );
};

const NewConditionAndUseNow = ({
  handleOpenCondition,
  actionStatus = ENUM_VOUCHER_ACTION_STATUS.AVAILABLE,
  actionLink,
  promoCode,
  setTriggerRefetchAllPromoList,
  isVoucherGift = false,
  isProductVoucherSection = false,
  voucherID,
  storeCode = '',
  sectionCode = '',
  sellerCode = '',
}: {
  handleOpenCondition: () => void;
  actionStatus: ENUM_VOUCHER_ACTION_STATUS;
  actionLink: string;
  promoCode: string;
  setTriggerRefetchAllPromoList?: React.Dispatch<React.SetStateAction<number>>;
  isVoucherGift?: boolean;
  isProductVoucherSection?: boolean;
  voucherID?: number;
  storeCode?: string;
  sectionCode?: string;
  sellerCode?: string;
}) => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }:any = useAuth()
  const { promoCodeTracking } = usePromoCodesV2Actions();
  const {
    reloadDataCart,
    multiVoucherHandleChangePromoV2,
  } = useCart();
  const redirectToLink = async (actionLink: string, actionStatus: ENUM_VOUCHER_ACTION_STATUS, promoCode: string) => {
    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE) {
      const res = await multiVoucherHandleChangePromoV2([promoCode]);

      if (res.status === 'OK') {
        await reloadDataCart({
          cartRes: res,
          successMessage: 'Thêm mã giảm giá thành công',
          errorMessage: 'Thêm mã giảm giá thất bại',
          isReloadData: true,
        });
        if (setTriggerRefetchAllPromoList) {
          setTriggerRefetchAllPromoList((prev) => prev + 1);
        }
        promoCodeTracking({
          customerID: String(user?.customerID),
          voucherID: String(voucherID),
          sectionCode,
          storeCode,
          sellerCode,
          trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_APPLY_VOUCHER_BUTTON,
        })
        promoCodeTracking({
          customerID: String(user?.customerID),
          voucherID: String(voucherID),
          sectionCode,
          storeCode,
          sellerCode,
          trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_STRANFOR_APPLYING_VOUCHER_BUTTON,
        })
      } else {
        switch (res.errorCode) {
          case 'NOT_FOUND': {
            NotifyUtils.error('Vui lòng thêm ít nhất một sản phẩm vào giỏ hàng và thử lại.');
            break;
          }
          case 'VOUCHER_CODE_INVALID': {
            NotifyUtils.error(res.message);
            break;
          }
          default: {
            NotifyUtils.error('Lỗi! Xin vui lòng kiểm tra lại.');
            break;
          }
        }
      }
    }

    if (actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID) {
      promoCodeTracking({
        customerID: String(user?.customerID),
        voucherID: String(voucherID),
        sectionCode,
        storeCode,
        sellerCode,
        trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_BUY_MORE_BUTTON,
      })
      // router.push(actionLink);
    }
  };

  // using cho section product in cart page
  if (isProductVoucherSection) {
    return (
      <Box display="flex" flexDirection="column" gridGap={24}>
        <Button
          style={{ color: '#0E1983', padding: '6px 0', textTransform: 'unset' }}
          onClick={() => {
            handleOpenCondition();
            promoCodeTracking({
              customerID: String(user?.customerID),
              voucherID: String(voucherID),
              sectionCode,
              sellerCode,
              storeCode,
              trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_VOUCHER_CONDITION,
            });
          }}
        >
          <ErrorOutline style={{ width: '20px', height: '20px' }} />
          <Typography variant="body2" style={{ paddingLeft: 8, fontSize: 12, lineHeight: '18px' }}>
            Điều kiện
          </Typography>
        </Button>

        {
          isVoucherGift ? (
            <Button
              className={clsx(
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.newVoucherGiftsCondition_btnBuyMore,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.newVoucherGiftCondition_btnUseNow,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.newVoucherGiftsCondition_btnUsing,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.newVoucherCondition_btnDisabled,
                styles.newVoucherCondition_btn,
              )}
              onClick={() => {
                redirectToLink(actionLink, actionStatus, promoCode);
              }}
            >
              <span>{VoucherBagVoucherConditionTextButton[actionStatus]}</span>
            </Button>
          ) : (
            <Button
              className={clsx(
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.newVoucherCondition_btnBuyMore,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.voucherCondition_btnUseNow,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.newVoucherCondition_btnUsing,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.newVoucherCondition_btnDisabled,
                styles.newVoucherCondition_btn,
              )}
              onClick={() => {
                redirectToLink(actionLink, actionStatus, promoCode);
              }}
            >
              <span>{VoucherBagVoucherConditionTextButton[actionStatus]}</span>
            </Button>
          )
        }

        <Box display="flex" alignItems="center" gridGap={4}>
          <Button
            className={styles.newVoucherConditionMoreProduct}
            onClick={() => {
              if (actionLink) {
                router.push(actionLink)
              } else {
                router.push(QUICK_ORDER)
              }
            }}
          >
            Xem thêm sản phẩm
            <ArrowForwardIosIcon style={{ fontSize: 16 }} />
          </Button>

        </Box>

      </Box>
    )
  }

  return (
    <Grid item md={12}>
      <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
        <Button
          style={{ color: '#0E1983', padding: '6px 0', textTransform: 'unset' }}
          onClick={() => {
            handleOpenCondition();
            promoCodeTracking({
              customerID: String(user?.customerID),
              voucherID: String(voucherID),
              sectionCode,
              storeCode,
              sellerCode,
              trackingType: ENUM_PROMOCODE_TRACKING_TYPE.PROMOCODE_CLICK_VOUCHER_CONDITION,
            });
          }}
        >
          <ErrorOutline style={{ width: '20px', height: '20px' }} />
          <Typography variant="body2" style={{ paddingLeft: 8, fontSize: 12, lineHeight: '18px' }}>
            Điều kiện
          </Typography>
        </Button>

        {
          isVoucherGift ? (
            <Button
              className={clsx(
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.newVoucherGiftsCondition_btnBuyMore,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.newVoucherGiftCondition_btnUseNow,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.newVoucherGiftsCondition_btnUsing,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.newVoucherCondition_btnDisabled,
                styles.newVoucherCondition_btn,
              )}
              onClick={() => {
                redirectToLink(actionLink, actionStatus, promoCode);
              }}
            >
              <span>{VoucherBagVoucherConditionTextButton[actionStatus]}</span>
            </Button>
          ) : (
            <Button
              className={clsx(
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INVALID && styles.newVoucherCondition_btnBuyMore,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.AVAILABLE && styles.voucherCondition_btnUseNow,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.INUSE && styles.newVoucherCondition_btnUsing,
                actionStatus === ENUM_VOUCHER_ACTION_STATUS.DISABLED && styles.newVoucherCondition_btnDisabled,
                styles.newVoucherCondition_btn,
              )}
              onClick={() => {
                redirectToLink(actionLink, actionStatus, promoCode);
              }}
            >
              <span>{VoucherBagVoucherConditionTextButton[actionStatus]}</span>
            </Button>
          )
        }
      </Grid>
    </Grid>
  );
};

const NewExpiredDateUsedTime = ({ expiredDate }: { expiredDate: string }) => {
  return (
    <Grid item md={12} xs={12} style={{ display: 'flex', gap: 4, justifyContent: 'center', whiteSpace: 'nowrap' }}>
      <Typography variant="body2" className={styles.newExpiredText}>
        NSD: {DateTimeUtils.getFormattedDate(new Date(expiredDate), 'DD/MM/YYYY')}{' '}
      </Typography>
    </Grid>
  );
};


const NewRequireTotalOrder = ({ orderID, mapOrderDetail }: { orderID?: string; mapOrderDetail?: IMapOrderDetail }) => {
  return (
    <Box display="flex" flexDirection="column" gridGap={4}>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" className={styles.requireOrder_text}>
          Đơn hàng: &nbsp;
        </Typography>
        {orderID && mapOrderDetail?.[orderID] && (
          <LinkComp
            href={`/my-order/${orderID}`}
            target="_blank"
            className={styles.redirectOrderID}
            name={undefined}
            onMouseOver={undefined}
            onClick={undefined}
          >
            <Typography variant="body2" className={styles.requireOrder_orderID}>
              #{orderID || ""}
            </Typography>
          </LinkComp>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" className={styles.requireOrder_text}>
          Giá trị đơn: &nbsp;
        </Typography>
        {orderID && mapOrderDetail?.[orderID] && (
          <Typography variant="body2" className={styles.requireOrder_price}>
            {formatCurrency(mapOrderDetail?.[orderID as keyof IMapOrderDetail]?.totalPrice || 0)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(VoucherV2);

