import { Dispatch, SetStateAction } from "react"

export enum ENUM_PROMO_CODE_TAB {
    ALL = "ALL",
    USED = 'USED'
}

export enum ENUM_FETCH_MORE_ACTION_TYPE {
    VOUCHER = 'VOUCHER',
    HISTORY = 'HISTORY',
    LOYALTY_POINT = 'LOYALTY_POINT',
    VOUCHER_COLLECTED = 'VOUCHER_COLLECTED',
    SELLER = 'SELLER',
    MARKETPLACE = 'MARKETPLACE',
}

export enum ENUM_PROMOTION_ORAGANIZER {
    INTERNAL_SELLER = "INTERNAL_SELLER",
    MARKETING = "MARKETING",
    SELLER_CENTER = "SELLER_CENTER"
}

export const PromoCodesTabInfo: Record<ENUM_PROMO_CODE_TAB, string> = {
    [ENUM_PROMO_CODE_TAB.ALL]: "Tất cả mã",
    [ENUM_PROMO_CODE_TAB.USED]: "Mã đã sử dụng"
}

export enum ENUM_VOUCHER_ACTION_STATUS {
    INVALID = "INVALID", // Buy more
    AVAILABLE = "AVAILABLE", // Use now
    DISABLED = "DISABLED", // Use now but disabled
    INUSE = "INUSE" // Using
}

export enum ENUM_PROMO_TYPE {
    DISCOUNT = 'DISCOUNT',
    COMBO = 'COMBO',
    GIFT = 'GIFT',
    VOUCHERCODE = 'VOUCHERCODE',
    FREESHIP = 'FREESHIP',
    PERCENTAGE = 'PERCENTAGE',
    ABSOLUTE = 'ABSOLUTE',
    POINT = 'POINT',
}

export enum ENUM_VOUCHER_COLLECTION_STATUS {
    AVAILABLE = 'AVAILABLE',
    COLLECTED = 'COLLECTED',
    OWNERSHIP = 'OWNERSHIP'
}

export enum ENUM_VOUCHER_SECTION_TYPE {
    BANNER              =              "BANNER",
	SUB_MENU            =            "SUB_MENU",
	VOUCHER_ME          =          "VOUCHER_ME",
	VOUCHER_SELLER      =      "VOUCHER_SELLER",
	VOUCHER_MARKETPLACE = "VOUCHER_MARKETPLACE",
	VOUCHER_PRODUCT     =     "VOUCHER_PRODUCT",
	EXCHANGE_LOYALTY    =    "EXCHANGE_LOYALTY",
	MINI_BANNER         =         "MINI_BANNER",
	REFERRAL            =            "REFERRAL",
	OTHER               =               "OTHER"
}

export enum ENUM_VOUCHER_SECTION_WIDTH {
    FULL = 'FULL',
    HALF = 'HALF'
}

export enum ENUM_VOUCHER_TYPE {
    SELLER = 'seller',
    MARKETPLACE = 'marketplace'
}

export enum ENUM_PROMOCODE_TRACKING_TYPE {
    PROMOCODE_CLICK_VOUCHER_CONDITION = "PROMOCODE_CLICK_VOUCHER_CONDITION",
    PROMOCODE_CLICK_BUY_MORE_BUTTON = "PROMOCODE_CLICK_BUY_MORE_BUTTON",
    PROMOCODE_CLICK_APPLY_VOUCHER_BUTTON = "PROMOCODE_CLICK_APPLY_VOUCHER_BUTTON",
    PROMOCODE_STRANFOR_APPLYING_VOUCHER_BUTTON = "PROMOCODE_STRANFOR_APPLYING_VOUCHER_BUTTON"
  }

export interface IMyDataVoucher {
    actionLink: string,
    actionStatus: ENUM_VOUCHER_ACTION_STATUS,
    canUse: boolean,
    code: string,
    conditionDescription: string,
    description: string,
    discount: number,
    displayName: string,
    endTime: string,
    errorMessage: string,
    groupCode: unknown,
    maxUsage: number,
    promotionName: string,
    publicTime: string,
    shortName: string,
    startTime: string,
    voucher: IVoucher,
    voucherId: number,
    usageTotal: number,
    voucherImage: string,
    tag: string,
    collectStatus: ENUM_VOUCHER_COLLECTION_STATUS,
    promotionOrganizer: ENUM_PROMOTION_ORAGANIZER,
    linkToStore: string,
    sellerName: string,
    gifts: IGifts[],
    productTags: string[],
    sellerCode: string,
    storeCode: string,
}

export interface IVoucher {
    appliedCustomers: number[],
    code: string,
    endTime: string,
    promotion: {
        description: string,
        rewardVoucher:{
            type: ENUM_PROMO_TYPE
        }[],
        rewards: {
            type: ENUM_PROMO_TYPE
        }[]
    },
    promotionName: string,
    startTime: string
}

export interface IOtherDataVoucher {
    actionLink: string,
    actionStatus: ENUM_VOUCHER_ACTION_STATUS,
    canUse: boolean,
    code: string,
    conditionDescription: string,
    description: string,
    displayName: string,
    endTime: string,
    errorMessage: string,
    gifts: IGifts[],
    groupCode: unknown,
    maxUsage: number,
    promotionName: string,
    publicTime: string,
    shortName: string,
    startTime: string,
    voucher: IVoucher,
    voucherId: number,
    usageTotal: number,
    voucherImage: string,
    tag: string,
    promotionOrganizer: ENUM_PROMOTION_ORAGANIZER,
    linkToStore: string,
    sellerName: string,
}

export interface IGifts {
    quantity: number,
    sku: string
}

export interface IPromoCodesTab {
    isMobile: boolean;
    currentTab: number;
    handleTabChange: (tab: number, obj?: {
      [x: string]: string | number
    }) => void
}

export interface IVoucherUsed {
    createdTime: string,
    customerId: number,
    orderID: number,
    type: unknown,
    usage: number,
    voucher: {
        code: string,
        shortName: string,
        conditionDescription: string,
        description: string,
        displayName: string,
        endTime: string,
        groupCode: unknown,
        maxUsage: 0,
        promotionName: string,
        publicTime: string,
        startTime: string,
        usageTotal: number,
        voucher: {
            appliedCustomers: null,
            promotion: unknown
        },
        voucherId: number
        voucherImage: string,
        tag: string
        promotionOrganizer: ENUM_PROMOTION_ORAGANIZER
    },
}


export interface ISectionListVoucher {
    isMobile: boolean;
    title?: string;
    icon?: JSX.Element;
    vouchers: IMyDataVoucher[] | IOtherDataVoucher[];
    isAllowFetchMore: boolean;
    dataMyVoucherShowMore?: IMyDataVoucher[];
    handleFetchMore?: (type: ENUM_FETCH_MORE_ACTION_TYPE) => void;
    isFetching?: boolean;
    limitMyVoucher?: number;
    totalMyVoucher?: number;
    setTriggerRefetchAllPromoList?: Dispatch<SetStateAction<number>>
}

export interface IMapOrderDetail {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
}

export interface ISectionListVoucherUsed {
    isMobile: boolean;
    vouchers: IVoucherUsed[];
    isFetching: boolean;
    handleFetchMore: (type: ENUM_FETCH_MORE_ACTION_TYPE) => void;
    limitUsedVoucher: number;
    totalUsedVoucher: number;
    mapOrderDetail: IMapOrderDetail
}

export interface IMiniBanner {
    code: string;
    imageIndex: number,
    imageURL: string,
    url: string
}
  

export const PromoCodeTabOpts: {
    index: number,
    label: string,
    value: ENUM_PROMO_CODE_TAB
}[] = [
    {
        index: 0,
        label: PromoCodesTabInfo.ALL,
        value: ENUM_PROMO_CODE_TAB.ALL
    },
    {
        index: 1,
        label: PromoCodesTabInfo.USED,
        value: ENUM_PROMO_CODE_TAB.USED
    }
]

export interface ISectionListVoucherExpandAll {
    title?: string;
    icon?: JSX.Element;
    vouchers: IMyDataVoucher[] | IOtherDataVoucher[];
    isShowAll: boolean;
    dataMyVoucherShowMore?: IMyDataVoucher[];
    handleFetchMore?: (type: ENUM_FETCH_MORE_ACTION_TYPE) => void;
    isFetching?: boolean;
    limitMyVoucher?: number;
    totalMyVoucher?: number;
    setTriggerRefetchAllPromoList?: Dispatch<SetStateAction<number>>
    seeMoreType: ENUM_FETCH_MORE_ACTION_TYPE
}

export interface IVoucherSection {
    banners: {
        imageUrl: string
        link: string
    }[],
    backgroundTitle: string
    code: string
    endTime: string
    isActive: string
    items: IVoucherSectionItems[]
    priority: number
    scope: {
        levels: string[]
        provinceCodes: string[]
        scopes: string[]
    }
    startTime: string
    title: string
    type: ENUM_VOUCHER_SECTION_TYPE
    voucherCodes: string[]
    mobileWidth: ENUM_VOUCHER_SECTION_WIDTH
    desktopWidth: ENUM_VOUCHER_SECTION_WIDTH
}

export interface IVoucherSectionItems {
    icon: string
    iconActive: string
    isActive: boolean
    name: string
    priority: number
    refSection: string
}

export interface ICartItem {
    quantity: number
    price: number
    total: number
    sku: string
    sellerCode: string
    productTags: string[]
    productCode: string
    isSelected: boolean
}

export interface IDataSellerVoucher {
    sellerCode: string
    name: string
    shortName: string
    avatar: string
    link: string
    vouchers: IMyDataVoucher[]
}

export interface ILoyaltyPoints {
    code: string;
    createdBy: string;
    createdTime: string;
    // eslint-disable-next-line camelcase
    is_delete: boolean;
    lastUpdatedTime: string;
    point: number;
    promotionId: number;
    status: string;
    updatedBy: string;
    promotion: {
      applyDiscount: {
        notInSkus: [];
        skus: [];
      };
      applyType: string;
      conditionDescription: string;
      conditions: null;
      createdBy: number;
      createdTime: string;
      description: string;
      endTime: string;
      maxAutoApplyCount: number;
      maxUsage: number;
      maxUsagePerCustomer: number;
      promotionId: number;
      promotionName: string;
      promotionOrganizer?: ENUM_PROMOTION_ORAGANIZER;
      promotionType: string;
      publicTime: string;
      rewards: [
        {
          absoluteDiscount: number;
          type: string;
        },
      ];
      scopes: [
        {
          quantityType: string;
          type: string;
        },
        {
          quantityType: string;
          type: string;
        },
        {
          quantityType: string;
          type: string;
        },
        {
          quantityType: string;
          type: string;
        },
      ];
      sellerCodes: string[];
      sellerName: string;
      shortName: string;
      startTime: string;
      status: string;
      tag: string;
      type: string;
      voucherGroupCode: string;
      voucherImage: string;
    };
}